import styled from 'styled-components';

export const TableResponsive = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Table = styled.table`
  border-collapse: collapse;
  color: rgb(65, 65, 74);
  font-size: 1.125rem;
  max-width: 100%;
  text-align: left;
  background: rgb(255, 255, 255);
  width: 100%;
  text-align: start;
`;

export const TableHeadTh = styled.th`
  font-family: FortumSans, arial, sans-serif;
  box-sizing: border-box;
  color: ${props => props.theme.main};
  line-height: 1.33;
  font-weight: 500;
  height: 3.5rem;
  position: relative;
  text-align: left;
  vertical-align: top;
  word-break: normal;
  padding: 1rem 2rem;
`;

export const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background: #f7f7f7;
  }
`;
