import actionCreator from '@/helpers/redux/actionCreator';

export const AUTHENTICATION_REQUEST = '@/AUTHENTICATION_REQUEST';
export const AUTHENTICATION_RESPONSE = '@/AUTHENTICATION_RESPONSE';
export const AUTHENTICATION_RESPONSE_FAIL = '@/AUTHENTICATION_RESPONSE_FAIL';
export const AUTHENTICATION_SAVE_REDIRECT_URL = '@/AUTHENTICATION_SAVE_REDIRECT_URL';

export const authenticationRequest = values => actionCreator(AUTHENTICATION_REQUEST, { values });

export const authenticationSaveRedirectUrl = values => actionCreator(AUTHENTICATION_SAVE_REDIRECT_URL, { values });

export const authenticationResponse = (name, displayableId, userIdentifier) => actionCreator(AUTHENTICATION_RESPONSE, {
  name,
  displayableId,
  userIdentifier,
});

export const authenticationResponseFail = error => actionCreator(AUTHENTICATION_RESPONSE_FAIL, { error });
