import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_FORM_REQUEST,
  getFormResponse,
  getFormResponseFail,
  getUserListRequest,
} from '@/actions';

import { getFormById, getFormByHRef } from '@/api/getForm';

function* getFormRequest({ payload }) {
  try {
    const { id, href, requestType } = payload;
    const response = yield call(requestType === 'id' ? getFormById : getFormByHRef, id);
    yield put(getFormResponse(response));
    if (requestType === 'id') yield put(push(href));
    yield put(getUserListRequest());
    return true;
  } catch (error) {
    yield put(getFormResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(GET_FORM_REQUEST, getFormRequest);
}
