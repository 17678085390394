import actionCreator from '@/helpers/redux/actionCreator';

export const GET_FORM_REQUEST = '@/GET_FORM_REQUEST';
export const GET_FORM_RESPONSE = '@/GET_FORM_RESPONSE';
export const GET_FORM_RESPONSE_FAIL = '@/GET_FORM_RESPONSE_FAIL';

export const getFormRequest = (requestType, id, href) => actionCreator(GET_FORM_REQUEST, { requestType, id, href });

export const getFormResponse = form => actionCreator(GET_FORM_RESPONSE, {
  form,
});

export const getFormResponseFail = error => actionCreator(GET_FORM_RESPONSE_FAIL, {
  error,
});
