import {
  Link,
  IconProfileOutline,
  spacing,
  Col,
} from 'elemental-ui';
import styled from 'styled-components';

export const ColTextStart = styled(Col)`
  text-align: start;
`;

export const TableResponsive = styled.div`
  display: block;
  max-width: 508px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

export const SearchBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

export const UsersListBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CategoriesBlock = styled.div`
  min-width: 230px;
`;

export const TableWrapperBlock = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const UnorderedList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const IconedLink = styled(Link)`
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
`;

export const Icon = styled(IconProfileOutline)`
  margin-right: ${spacing.xxxxs}
`;
