import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Button, ContentText } from 'elemental-ui';

import Page from '@/components/blocks/Page';

const textCenter = { textAlign: 'center' };

class NotAuthorizedPage extends React.PureComponent {
  handleLogOut = () => {
    const { redirect_url } = this.props;
    const { authenticationRequest } = this.props;
    const postLogOutLogin = true;
    authenticationRequest({ redirect_url, postLogOutLogin });
  };

  render() {
    const { error } = this.props;

    const errorMessage = typeof error === 'object' ? '' : error;
    return (
      <Page>
        <Heading level={4} contentHeading style={textCenter}>
          You are not authorized.
        </Heading>
        <ContentText style={{ textAlign: 'center' }}>
          {' '}
          {errorMessage !== null ? errorMessage.replace(/.+([:|])/, '') : ''}
        </ContentText>
        <Button onClick={this.handleLogOut}>Login</Button>
      </Page>
    );
  }
}

NotAuthorizedPage.propTypes = {
  authenticationRequest: PropTypes.func.isRequired,
  error: PropTypes.any,
  redirect_url: PropTypes.string.isRequired,
};
NotAuthorizedPage.defaultProps = {
  error: '',
};

export default NotAuthorizedPage;
