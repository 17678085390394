import React from 'react'
import PropTypes from 'prop-types'
import { MSPDatePicker } from './styles'
import { Text } from "@/components/blocks/Text"
import { Row } from '../Grid'

export function DateField(props) {
    const { label, required, onChange, onBlur, error, errorMessage, name, id } = props;
    return (
        <div>
            <MSPDatePicker {...props} />      
            <Row>
                {error && <Text variant="warning">{errorMessage}</Text>}
            </Row>
        </div>
    )
}

DateField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
}

DateField.defaults = {
    label: "Placeholder",
    required: false
}
