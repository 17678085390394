import React from 'react';
import {
  Box, spacing,
} from 'elemental-ui';
import { Button } from "../Button"

class SaveFormAction extends React.Component {
  render() {
    return (
      <Box
        marginBottom={spacing.s}
        marginTop={spacing.s}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          type="submit"
          status="primary"
          marginRight={spacing.xxs}
          onClick={this.handleOpen}
        >
          Save
        </Button>
      </Box>
    );
  }
}

SaveFormAction.propTypes = {
};

export default SaveFormAction;
