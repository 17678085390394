import {
  GET_FORM_REQUEST,
  GET_FORM_RESPONSE,
  GET_FORM_RESPONSE_FAIL,
} from '@/actions';

const initialState = {
  requested: false,
  isRequested: false,
  form: {
    formConfiguration: {
      fields: [],
      contentInterfaceID: '',
      title: '',
    },
    document_id: '',
    href: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FORM_REQUEST:
      return {
        ...state,
        requested: true,
        isRequested: false,
      };
    case GET_FORM_RESPONSE:
      return {
        ...state,
        form: action.payload.form,
        isRequested: true,
      };
    case GET_FORM_RESPONSE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        requested: false,
        isRequested: false,
      };
    default:
      return state;
  }
}
