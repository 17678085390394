import {
  CHECK_USER_GROUP_REQUEST,
  CHECK_USER_GROUP_RESPONSE,
  CHECK_USER_GROUP_RESPONSE_FAIL,
} from '@/actions';

const initialState = {
  requested: false,
  error: '',
  userIsAdmin: false,
  isRequested: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_USER_GROUP_REQUEST:
      return {
        ...state,
        requested: true,
        isRequested: false,
      };
    case CHECK_USER_GROUP_RESPONSE:
      return {
        ...state,
        ...action.payload.permissions,
        requested: false,
        isRequested: true,
      };
    case CHECK_USER_GROUP_RESPONSE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        requested: false,
        isRequested: true,
      };
    default:
      return state;
  }
}
