import { connect } from 'react-redux';

import InputContactPerson from './component';
import { getUserRequest, removeTag } from '@/actions';

const mapStateToProps = state => ({
  requestError: state.getUser.error,
  tags: state.contactPersonField.tags,
});
const mapDispatchToProps = {
  getUserRequest,
  removeTag,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InputContactPerson);
