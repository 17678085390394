import { userAgentApplication } from '@/helpers/azure/authentication';
import { AZURE_AUTHENTICATION_SCOPES } from '@/constants';

export const getAzureApiToken = async () => {
  const idToken = sessionStorage.getItem('msal.idtoken');

  if (!idToken) {
    throw new Error('You need to be authenticated in Azure before accessing the token');
  }

  const token = await new Promise((resolve, reject) => userAgentApplication.acquireTokenSilent(AZURE_AUTHENTICATION_SCOPES)
    .then(accessToken => resolve(accessToken))
    .catch(error => reject(error)));

  return token;
};
