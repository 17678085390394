export const INPUT_COMPONENT = 'InputField';
export const CONTACT_PERSON_COMPONENT = 'ContactPerson';
export const RADIO_BUTTON_GROUP_COMPONENT = 'RadioButtonGroup';
export const TEXT_AREA_COMPONENT = 'Textarea';
export const SELECT_COMPONENT = 'Select';
export const CHECK_BOX_GROUP_COMPONENT = 'CheckboxGroup';
export const CHECKBOX_COMPONENT = 'Checkbox';
export const DATEPICKER_COMPONENT = 'DatePicker';
export const FILE_UPLOADER_COMPONENT = 'FileUploader';
export const ITEMS_EDIT_COMPONENT = 'ItemsEdit';
