import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import LandingPage from '@/components/pages/LandingPage';

import DynamicFormPage from '@/components/pages/DynamicFormPage';

const WorkspacesPage = ({ formsList }) => (
  <Router>
    <Switch>
      {formsList.map(form => (
        <Route
          key={form.document_id}
          path={form.href}
          component={DynamicFormPage}
        />
      ))}
      {/* It's a temporary solution to show not implemented page */}
      <Route
        path="*"
        render={() => (
          <LandingPage heading="One moment..." />
        )}
      />
    </Switch>
  </Router>
);

WorkspacesPage.propTypes = {
  formsList: PropTypes.array.isRequired,
};

WorkspacesPage.defaultProps = {};

export default WorkspacesPage;
