import { FORTUM_FORMS_BACKEND_ENDPOINT } from '@/constants';

export default id => fetch(`${FORTUM_FORMS_BACKEND_ENDPOINT}/api/forms/${id}`, {
  headers: {
    'Access-Control-Allow-Origin': FORTUM_FORMS_BACKEND_ENDPOINT,
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Content-Type': 'application/json',
  },
  method: 'DELETE',
}).then(res => console.log(res));
