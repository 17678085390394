import {
  SAVE_FORM_UPDATES,
  UPDATE_FORM,
  UPDATE_FORM_REQUEST,
  UPDATE_FORM_RESPONSE,
  UPDATE_FORM_RESPONSE_FAIL,
  UPDATE_BEFORE_CREATE,
} from '@/actions';

const initialState = {
  formIsUpdated: false,
  isRequested: false,
  error: '',
  id: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_BEFORE_CREATE:
      return {
        ...state,
        formIsUpdated: true,
        id: action.payload.id,
      };
    case UPDATE_FORM:
      return {
        ...state,
        formIsUpdated: true,
      };
    case SAVE_FORM_UPDATES:
      return {
        ...state,
        formIsUpdated: false,
      };
    case UPDATE_FORM_REQUEST:
      return {
        ...state,
        isRequested: true,
      };
    case UPDATE_FORM_RESPONSE:
      return {
        ...state,
        isRequested: false,
      };
    case UPDATE_FORM_RESPONSE_FAIL:
      return {
        ...state,
        isRequested: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
