import {
  REDIRECT_ACTION,
} from '@/actions';

const initialState = {
  redirected: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REDIRECT_ACTION:
      return { ...state, redirected: true };
    default:
      return state;
  }
}
