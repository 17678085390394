import styled from "styled-components"


const ColorVariant = {
    dark: "#333",
    neutral: "#fff"
}

export const ButtonElement = styled.button`
    position: relative;

    background-color: ${props => (props.outlined ? 'transparent' : (props.theme.button[props.variant] || props.theme.button.default))};
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: ${props => (props.outlined ? 'solid' : 'none')};
    border-width: 1px;
    color: ${props => props.theme.main} !important;

    margin-left: 0.5rem;
    margin-right: 0.5rem;

    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;

    :disabled {
        background-color: ${props => props.theme.disabled};
    }

    :after {
        content: "";
        background-color: ${props => props.theme.disabled};
        display: block;
        position: absolute;
        padding-top: 300%;
        padding-left: 350%;
        margin-left: -2rem!important;
        margin-top: -120%;
        opacity: 0;
        transition: all 0.8s
    }

    :active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s
    }

    :hover {
        cursor: pointer;
    }
`

export const IconButtonElement = styled(ButtonElement)`
    background-color: transparent;
    border: none;
    width: 25px;
    height: 25px;
    padding: 0px;
    border-radius: 100%;
    color: ${props => props.var};

    svg {
        color: ${props => (ColorVariant[props.variant] || ColorVariant.dark)};
    }

    :after {
        background-color: ${props => props.theme.main};
    }
`

export const ButtonLabel = styled.span`
    color: ${props => (props.outlined ? props.theme.main : (props.disabled ? props.theme.neutral : '#fff'))};
`