import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CANCEL_SUBMIT_FORM } from '@/actions';

function* cancelSubmit({ payload }) {
  try {
    const { redirectUrl } = payload;
    yield put(push(redirectUrl));
  } catch (error) {
    console.log('>e', error);
  }
}

export default function* () {
  yield takeLatest(CANCEL_SUBMIT_FORM, cancelSubmit);
}
