import React from 'react'
import PropTypes from 'prop-types'
import { BaseInput, BaseInputContainer, Label } from './styles'
import { Text } from "@/components/blocks/Text"
import { Row } from '../Grid';

export function TextField(props) {
    const { label, required, marginTop, marginBottom, id, name, onChange, onBlur, error, errorMessage, value } = props;
    const styles = {
        marginTop: marginTop || "0px",
        marginBottom: marginBottom || "0px"
    }

    return (
        <div style={styles}>
            <BaseInputContainer>
                <BaseInput type="text" id={id} name={name} placeholder={value || label || ""} onChange={onChange} onBlur={onBlur} autoComplete="off" />
            </BaseInputContainer>

            <Row>
                {error && <Text variant="warning">{errorMessage}</Text>}
            </Row>
        </div>
    )
}

TextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
}

TextField.defaults = {
    label: "Placeholder",
    required: false,
    marginBottom: "0px",
    marginTop: "0px",
    error: false,
    errorMessage: ""
}
