import {
  GET_FORMS_LIST_REQUEST,
  GET_FORMS_LIST_RESPONSE_FAIL,
  GET_FORMS_LIST_RESPONSE,
  RESET_PAGINATION,
  SET_PAGINATION_PAGE,
} from '@/actions';

const initialState = {
  requested: false,
  formsList: [],
  activePaginationPage: 1,
  countPaginationPage: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_PAGINATION:
      return {
        ...state,
        activePaginationPage: 1,
        countPaginationPage: 0,
      };
    case SET_PAGINATION_PAGE:
      return {
        ...state,
        activePaginationPage: action.payload.page,
      };
    case GET_FORMS_LIST_REQUEST:
      return {
        ...state,
        requested: true,
      };
    case GET_FORMS_LIST_RESPONSE:
      return {
        ...state,
        formsList: action.payload.formsList,
        countPaginationPage: Math.ceil(action.payload.formsList.length / 5),
      };
    case GET_FORMS_LIST_RESPONSE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        requested: false,
        countPaginationPage: 0,
      };
    default:
      return state;
  }
}
