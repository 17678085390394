import styled from 'styled-components';
import { IconCheck } from 'elemental-ui';

export const NotificationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5ac37d;
  color: white;
  width: 250px;
  z-index: 9999;
  position: fixed;
  bottom: 10%;
  left: 85%;
`;

export const IconNotification = styled(IconCheck)`
transform: scale(0.5);
`;
