import React from 'react';

import {
  Col,
  InputField,
  Box,
  ContentText,
  IconDelegate,
} from 'elemental-ui';

import { TextField, SelectField, CheckField, RadioButtonItems, TextAreaField, DateField, FileUploadField } from "@/components/blocks/Input"

import { getValidator } from '@/helpers/validators';
import {
  FIELD_BOX_MARGIN_BOTTOM,
  FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM,
  INPUT_COMPONENT,
  CONTACT_PERSON_COMPONENT,
  RADIO_BUTTON_GROUP_COMPONENT,
  CHECKBOX_COMPONENT,
  TEXT_AREA_COMPONENT,
  SELECT_COMPONENT,
  CHECK_BOX_GROUP_COMPONENT,
  DATEPICKER_COMPONENT,
  FILE_UPLOADER_COMPONENT,
} from '@/constants';
import { checkboxStyle, radioButtonStyle } from '@/styles';
import { StyledContentText } from './styles';
import { format } from 'date-fns';
import { Text } from '@/components/blocks/Text';


export const COMPARE_ENTITIES = {
  FIELD_VALUE: 'FIELD_VALUE',
};

const flow = ({ field, values }, component) => {
  return component;
};


export const extractDefaultValues = fields => (fields || []).reduce((acc, field) => {
  acc[field.name] = field.defaultValue || '';

  return acc;
}, {});

export const automaticValidate = (fields, values) => {
  const errors = {};

  (fields || []).forEach((field) => {
    const { required, validator, invalidMessage } = field;

    const validatorFunction = getValidator(validator);

    if (required && validatorFunction) {
      const validationResult = validatorFunction(values[field.name]);

      if (!validationResult) {
        errors[field.name] = invalidMessage || 'Fields has error';
      }
    }
  });

  return errors;
};

export const extractField = (props) => {
  switch (props.field.component) {
    case INPUT_COMPONENT:
      return extractTextField(props);
    case CONTACT_PERSON_COMPONENT:
      return extractContactPersonField(props);
    case RADIO_BUTTON_GROUP_COMPONENT:
      return extractRadioGroupField(props);
    case TEXT_AREA_COMPONENT:
      return extractTextArea(props);
    case SELECT_COMPONENT:
      return extractSelect(props);
    case CHECK_BOX_GROUP_COMPONENT:
      return extractCheckboxGroup(props);
    case CHECKBOX_COMPONENT:
      return extractCheckBox(props);
    case DATEPICKER_COMPONENT:
      return extractDatePicker(props);
    case FILE_UPLOADER_COMPONENT:
      return extractFileUploader(props);
    default:
      return null;
  }
};

export const extractCheckBox = (props) => {
  const {
    field, isSubmitting, handleChange, selectElement,
  } = props;
  return flow(
    props,
    <Col tabIndex="0" key={field.id} l={12} onFocus={event => selectElement(field.id, event)} style={{ cursor: 'pointer' }}>
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <CheckField
          style={{ pointerEvents: 'none', ...checkboxStyle }}
          key={field.id}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          disabled={isSubmitting}
          onChange={handleChange}
        />
      </Box>
    </Col>,
  );
};

export const extractFileUploader = (props) => {
  const {
    field, setFieldValue, selectElement,
  } = props;
  return flow(
    props,
    <Col tabIndex="0" key={field.id} l={12} onFocus={event => selectElement(field.id, event)} style={{ cursor: 'pointer' }}>
      <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
        {field.context ? field.context : ''}
      </StyledContentText>
      <FileUploadField
        style={{ pointerEvents: 'none' }}
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.id}
        name={field.name}
        label={field.required ? `${field.label}*` : `${field.label}`}
        onChange={(e, data) => {
          setFieldValue(field.name, data.dataURI || '');
          setFieldValue(field.fileUploadSize, data.file ? data.file.size : 311);
        }}
      />
    </Col>,
  );
};

export const extractDatePicker = (props) => {
  const {
    field, handleBlur, isSubmitting, values, setFieldValue, selectElement,
  } = props;
  return flow(
    props,
    <Col
      tabIndex="0"
      key={field.id}
      xxs={12}
      m={field.m || 12}
      l={field.l || 12}
      onFocus={event => selectElement(field.id, event)}
      style={{ cursor: 'pointer' }}
    >
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <DateField
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          disabled={isSubmitting}
          value={values[field.name]}
          onChange={(date) => {
            const formattedDate = format(date, 'DD.MM.YYYY');
            setFieldValue(field.name, formattedDate);
          }}
          onBlur={handleBlur}
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          locale="en"
          style={{ pointerEvents: 'none' }}
        />
      </Box>
    </Col>,
  );
};

export const extractCheckboxGroup = (props) => {
  const {
    field, handleChange, handleBlur, isSubmitting, selectElement,
  } = props;
  return flow(
    props,
    <Col tabIndex="0" key={field.id} l={12} onFocus={event => selectElement(field.id, event)} style={{ cursor: 'pointer' }}>
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        {field.items.map(item => (
          <CheckField
            marginBottom={FIELD_BOX_MARGIN_BOTTOM}
            key={item.value}
            name={`${field.name}-${item.value}`}
            label={item.label}
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ pointerEvents: 'none', ...checkboxStyle }}
            pointerEvents={'none'}
          />
        ))}
      </Box>
    </Col>,
  );
};

export const extractSelect = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    selectElement,
  } = props;
  return flow(
    props,
    <Col
      tabIndex="0"
      key={field.id}
      xxs={12}
      m={field.m || 12}
      l={field.l || 12}
      onFocus={event => selectElement(field.id, event)}
      style={{ cursor: 'pointer' }}
    >
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <SelectField
          key={field.id}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          selected={values[field.name]}
          items={field.items}
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors[field.name] && touched[field.name] && errors[field.name]
          }
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          style={{ pointerEvents: 'none' }}
        />
      </Box>
    </Col>,
  );
};

export const extractTextArea = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    selectElement,
  } = props;
  return flow(
    props,
    <Col tabIndex="0" key={field.id} l={12} onFocus={event => selectElement(field.id, event)} style={{ cursor: 'pointer' }}>
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <TextAreaField
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          value={values[field.name]}
          error={!!(touched[field.name] && errors[field.name])}
          errorMessage={touched[field.name] && errors[field.name]}
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors[field.name] && touched[field.name] && errors[field.name]
          }
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          InputLabelProps={field.InputLabelProps || {}}
          style={{ pointerEvents: 'none' }}
        />
      </Box>
    </Col>,
  );
};

export const extractContactPersonField = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    selectElement,
  } = props;
  return flow(
    props,
    <Col
      tabIndex="0"
      key={field.id}
      xxs={12}
      m={field.m || 12}
      l={field.l || 12}
      onFocus={event => selectElement(field.id, event)}
      style={{ cursor: 'pointer' }}
    >
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <InputField
          style={{ pointerEvents: 'none' }}
          key={field.id}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          value={values[field.name]}
          error={!!(touched[field.name] && errors[field.name])}
          errorMessage={touched[field.name] && errors[field.name]}
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors[field.name] && touched[field.name] && errors[field.name]
          }
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          InputLabelProps={field.InputLabelProps || {}}
          rightIcon={<IconDelegate />}
        />
      </Box>
    </Col>,
  );
};

export const extractTextField = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    selectElement,
  } = props;
  return flow(
    props,
    <Col
      tabIndex="0"
      key={field.id}
      xxs={12}
      m={field.m || 12}
      l={field.l || 12}
      onFocus={event => selectElement(field.id, event)}
      style={{ cursor: 'pointer' }}
    >
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <TextField
          style={{ pointerEvents: 'none' }}
          key={field.id}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          value={values[field.name]}
          error={!!(touched[field.name] && errors[field.name])}
          errorMessage={touched[field.name] && errors[field.name]}
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors[field.name] && touched[field.name] && errors[field.name]
          }
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          InputLabelProps={field.InputLabelProps || {}}
        />
      </Box>
    </Col>,
  );
};

export const extractRadioGroupField = (props) => {
  const {
    field,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    selectElement,
  } = props;
  return (
    <Col tabIndex="0" key={field.id} l={12} onFocus={event => selectElement(field.id, event)} style={{ cursor: 'pointer' }}>
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.id}>
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label}
          {' '}
          {field.required &&<Text variant="warning">*</Text>}
        </ContentText>
        <RadioButtonItems
          style={{ pointerEvents: 'none' }}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          key={`${field.name}_${field.items.value}`}
          value={values[field.name]}
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          items={field.items}
          id={field.name}
          radioButtonStyle={radioButtonStyle}
          error={
            errors[field.name] && touched[field.name] && errors[field.name]
          }
        />
      </Box>
    </Col>
  );
};
