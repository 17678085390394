import React, { useState } from 'react'
import { FileInput, FileInputLabel, FileUploadContainer, Label } from "./styles"
import { Text } from "@/components/blocks/Text"
import {PropTypes} from 'prop-types'
import { Row } from '../Grid';
import { toBase64 } from '@/helpers/form/inputHelpers';

export function FileUploadField(props) {
    const { label, required, error, errorMessage, onChange, marginBottom, marginTop } = props;
    const [fileName, setFileName] = useState(null);

    const styles = {
        marginTop: marginTop,
        marginBottom: marginBottom
    }

    const handleFile = (e) => {
        let file = e.target.files[0];
        toBase64(file).then(result => {
            let fileData = {
                dataURI: result,
                file: file
            }
            setFileName(file.name);
            onChange(e, fileData);
            
        }).catch(e => console.error(e));  
    }

    return (
        <div style={styles}>
            <FileUploadContainer>
                <FileInput type="file" {...props} id={props.name} onChange={handleFile} />

                <FileInputLabel htmlFor="fileUpload">{label}</FileInputLabel>
                <Label>{fileName || "no file selected"}</Label>
            </FileUploadContainer>
            <Row>
                {error && <Text variant="warning">{errorMessage}</Text>}
            </Row>
        </div>
    )
}

FileUploadField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    marginBottom: PropTypes.string,
    marginTop: PropTypes.string
}

FileUploadField.defaults = {
    label: "Placeholder",
    required: false
}
