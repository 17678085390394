export default [
  {
    name: 'Checkbox',
  },
  {
    name: 'CheckboxGroup',
  },
  {
    name: 'ContactPerson',
  },
  {
    name: 'DatePicker',
  },
  {
    name: 'FileUploader',
  },
  {
    name: 'InputField',
  },
  {
    name: 'Select',
  },
  {
    name: 'Textarea',
  },
  {
    name: 'RadioButtonGroup',
  },
];
