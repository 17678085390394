import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_USERLIST_REQUEST,
  getUserListResponse,
  getUserListResponseFail,
  setTags,
} from '@/actions';

import { getUserList } from '@/api/getUserList';

function* getUserListRequest() {
  try {
    const response = yield call(getUserList);
    if (Object.prototype.hasOwnProperty.call(response, 'error')) {
      yield put(getUserListResponseFail('Users not found'));
      return false;
    }

    var ntag = Object.keys(response.value).map(resp => {
        console.log(response.value[resp]);
      return {
        name: `${response.value[resp].surname} ${response.value[resp].givenName}`,
        email: response.value[resp].userPrincipalName,
      };
    });
    yield put(getUserListResponse(ntag));
    yield put(setTags(ntag));
    return true;
  } catch (error) {
    yield put(getUserListResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(GET_USERLIST_REQUEST, getUserListRequest);
}
