import styled from 'styled-components';
import { IconSafety, Box, ContentText } from 'elemental-ui';

export const AccessDeniedIcon = styled(IconSafety)`
  color: #eb6437;
  transform: scale(2.5);
  margin-bottom: 30px;
`;

export const AccessDeniedContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -70px;
`;

export const AccessDeniedTitle = styled(ContentText)``;
