import { compose } from 'redux';
import { connect } from 'react-redux';
import Head from './component';

const mapStateToProps = state => ({
  name: state.user.name,
  userIsAdmin: state.checkUserGroups.userIsAdmin,
});

const mapDispatchToProps = null;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Head);
