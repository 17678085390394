import { compose } from 'redux';
import { connect } from 'react-redux';

import DynamicFormPage from './component';
import {
  authenticationRequest, editFieldOpen, setFormikParameters, saveFormikValues,
} from '@/actions';

const mapStateToProps = state => ({
  form: state.getForm.form,
});
const mapDispatchToProps = ({
  authenticationRequest,
  editFieldOpen,
  setFormikParameters,
  saveFormikValues,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DynamicFormPage);
