import React from 'react';

import { Col, Box } from 'elemental-ui';
import { TextField, SelectField, CheckField, RadioButtonItems, TextAreaField, DateField, FileUploadField } from "@/components/blocks/Input"
import { checkboxGroupMandatorySelection } from '@/helpers/validators/checkboxGroupValidator';
import { Field } from 'formik';
import { getValidator } from '@/helpers/validators';
import { checkboxStyle, radioButtonStyle } from '@/styles';
import { format } from 'date-fns';
import InputContactPerson from '@/components/blocks/InputContactPerson';
import { StyledContentText, StyledLabelText } from './styles';
import {
  FIELD_BOX_MARGIN_BOTTOM,
  FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM,
  INPUT_COMPONENT,
  CONTACT_PERSON_COMPONENT,
  RADIO_BUTTON_GROUP_COMPONENT,
  CHECKBOX_COMPONENT,
  TEXT_AREA_COMPONENT,
  SELECT_COMPONENT,
  CHECK_BOX_GROUP_COMPONENT,
  DATEPICKER_COMPONENT,
  FILE_UPLOADER_COMPONENT,
  ITEMS_EDIT_COMPONENT,
} from '@/constants';
import { Text } from '@/components/blocks/Text';
import { SingleCheckField } from '@/components/blocks/Input/SingleCheckField';


export const COMPARE_ENTITIES = {
  FIELD_VALUE: 'FIELD_VALUE',
};

export const extractDefaultValues = (fields) => {
  return (fields || [])
    .reduce((acc, field) => {
      acc[field.name] = field.defaultValue || '';

      return acc;
    }, {});
};

export const automaticValidate = (fields, values) => {
  const errors = {};
  (fields || [])
    .forEach(field => {
      const { required, validator, invalidMessage, component } = field;
      const validatorFunction = getValidator(validator);
      if (required && validatorFunction) {
        const validationResult = validatorFunction(values[field.name]);

        if (!validationResult) {
          errors[field.name] = invalidMessage || 'Fields has error';
        }
      }
      else if (required && values[field.name].length === 0 && component !== 'FileUploader' && component !== 'CheckboxGroup') {
        errors[field.name] = 'Fields is required';
      }
      else if (required && component === 'FileUploader' && (values.fileUploadSize === null
        || !Object.prototype.hasOwnProperty.call(values, 'fileUploadSize'))) {
        errors[field.name] = 'Fields is required';
      }
    });

  return errors;
};

export const extractField = (props) => {

  switch (props.field.component) {
    case INPUT_COMPONENT: return extractTextField(props);
    case CONTACT_PERSON_COMPONENT: return extractContactPersonField(props);
    case RADIO_BUTTON_GROUP_COMPONENT: return extractRadioGroupField(props);
    case TEXT_AREA_COMPONENT: return extractTextArea(props);
    case SELECT_COMPONENT: return extractSelect(props);
    case CHECK_BOX_GROUP_COMPONENT: return extractCheckboxGroup(props);
    case CHECKBOX_COMPONENT: return extractCheckBox(props);
    case DATEPICKER_COMPONENT: return extractDatePicker(props);
    case FILE_UPLOADER_COMPONENT: return extractFileUploader(props);
    case ITEMS_EDIT_COMPONENT: return extractItemsEdit(props);
    default: return (null);
  }
};

export const extractItemsEdit = (props) => {
  const {
    field,
    isSubmitting,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
  } = props;
  return flow(props,
    <Col key={field.name} l={12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} {field.required && <Text variant="warning">*</Text>}
        </StyledLabelText>
        <TextAreaField
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.label}
          value={values[field.name]}
          error={!!(touched[field.name] && errors[field.name])}
          errorMessage={touched[field.name] && errors[field.name]}
          disabled={isSubmitting}
          required={field.required}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors[field.name] && touched[field.name] && errors[field.name]}
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          InputLabelProps={field.InputLabelProps || {}}
        >
        </TextAreaField>
      </Box>
    </Col>
  );
}

export const extractCheckBox = (props) => {
  const {
    field,
    isSubmitting,
    handleChange,
    values,
  } = props;

  return flow(props,
    <Col key={field.name} l={12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <SingleCheckField
          key={field.name}
          name={field.name}
          label={field.label}
          required={field.required}
          style={checkboxStyle}
          disabled={isSubmitting}
          onChange={handleChange}
          checked={values[field.name]}
        />
      </Box>
    </Col>
  );
}

export const extractFileUploader = (props) => {
  const {
    field,
    setFieldValue,
  } = props;

  return flow(props,
    <Col key={field.name} l={12}>
       <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
      <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
        {field.context ? field.context : ''}
      </StyledContentText>
      <FileUploadField
        key={field.name}
        name={field.name}
        label={`${field.label} ${field.required ? '*' : ''}`}
        required={field.required}
        onChange={(e, data) => {
          setFieldValue(field.name, data.dataURI ? `${data.file.name}; ${data.dataURI}` : '');
          setFieldValue(field.fileUploadSize, data.file ? data.file.size : null);
        }}
      >
      </FileUploadField>
      </Box>
    </Col>
  );
}

export const extractDatePicker = (props) => {
  const {
    field,
    handleBlur,
    isSubmitting,
    values,
    setFieldValue,
  } = props;

  return flow(props,
    <Col key={field.name} xxs={12} m={field.m || 12} l={field.l || 12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} <Text variant="warning">{field.required ? '*' : ''}</Text>
        </StyledLabelText>
        <DateField
          autoComplete="off"
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.label}
          disabled={isSubmitting}
          required={field.required}
          value={values[field.name]}
          onChange={e => {
            const formattedDate = format(e, 'DD.MM.YYYY');
            setFieldValue(field.name, formattedDate);
          }}
          onBlur={handleBlur}
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          locale="en"
        />
      </Box>
    </Col>
  );
}

export const extractCheckboxGroup = (props) => {
  const {
    field,
    handleChange,
    handleBlur,
    isSubmitting,
    values,
  } = props;

  return flow(props,
    <Col key={field.name} l={12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText >
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} <Text variant="warning">{field.required ? '*' : ''}</Text>
        </StyledLabelText>
        {field.required ?
          <Field style={{ display: 'none' }}
            name={field.name}
            validate={() => !checkboxGroupMandatorySelection(values, field.name, field.items)}
          />
          :
          null}
        {
          field.items.map(item => (
            <CheckField
              marginBottom={FIELD_BOX_MARGIN_BOTTOM}
              key={item.value}
              style={checkboxStyle}
              name={`${field.name}-----${item.value}`}
              label={item.label}
              disabled={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ))}
      </Box>
    </Col>
  );
}

export const extractSelect = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
  } = props;

  return flow(props,
    <Col key={field.name} xxs={12} m={field.m || 12} l={field.l || 12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} <Text variant="warning">{field.required ? '*' : ''}</Text>
        </StyledLabelText>
        <SelectField
          key={field.name}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.required ? `${field.label}*` : `${field.label}`}
          selected={values[field.name]}
          items={field.items}
          disabled={isSubmitting}
          required={field.required}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors[field.name] && touched[field.name] && errors[field.name]}
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
        >
        </SelectField>
      </Box>
    </Col>
  );
}

export const extractTextArea = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
  } = props;

  return flow(props,
    <Col key={field.name} l={12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText >
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} <Text variant="warning">{field.required ? '*' : ''}</Text>
        </StyledLabelText>
        <TextAreaField
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.label}
          value={values[field.name]}
          error={!!(touched[field.name] && errors[field.name])}
          errorMessage={touched[field.name] && errors[field.name]}
          disabled={isSubmitting}
          required={field.required}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors[field.name] && touched[field.name] && errors[field.name]}
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
          InputLabelProps={field.InputLabelProps || {}}
        />
      </Box>
    </Col>
  );
}

export const extractContactPersonField = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
  } = props;
  return flow(props,
    <Col key={field.name} xxs={12} m={field.m || 12} l={field.l || 12}>
      <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
        {field.context ? field.context : ''}
      </StyledContentText>
      <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} {field.required ? '*' : ''}
        </StyledLabelText>
      <InputContactPerson
        field={field}
        errors={errors}
        touched={touched}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
      />
    </Col>
  );
};

export const extractTextField = (props) => {
  const {
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
  } = props;

  return flow(props,
    <Col key={field.name} xxs={12} m={field.m || 12} l={field.l || 12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} <Text variant="warning">{field.required ? '*' : ''}</Text>
        </StyledLabelText>
        <TextField
          key={field.name}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          label={field.label}
          value={values[field.name]}
          error={!!(touched[field.name] && errors[field.name])}
          errorMessage={touched[field.name] && errors[field.name]}
          disabled={isSubmitting}
          required={field.required}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors[field.name] && touched[field.name] && errors[field.name]}
          id={field.name}
          className={field.className || ''}
          margin={field.margin || 'normal'}
        >
        </TextField>
      </Box>
    </Col>
  );
};

export const extractRadioGroupField = (props) => {
  const {
    field,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
  } = props;

  return (
    <Col key={field.name} l={12}>
      <Box
        marginBottom={FIELD_BOX_MARGIN_BOTTOM}
        key={field.name}
      >
        <StyledContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </StyledContentText>
        <StyledLabelText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.label} <Text variant="warning">{field.required ? '*' : ''}</Text>
        </StyledLabelText>
        <RadioButtonItems
          label={field.label}
          marginBottom={FIELD_BOX_MARGIN_BOTTOM}
          name={field.name}
          key={`${field.name}_${field.items.value}`}
          value={values[field.name]}
          disabled={isSubmitting}
          required={field.required}
          onChange={handleChange}
          onBlur={handleBlur}
          items={field.items}
          id={field.name}
          radioButtonStyle={radioButtonStyle}
          error={errors[field.name] && touched[field.name] && errors[field.name]}
        />
      </Box>
    </Col>
  );
};

const flow = ({ field, errors, values }, component) => {
  if (field.visibleIf && field.visibleIf.length > 0) {
    if (!visibleIf(field.visibleIf, values)) {
      return (null);
    }
  }

  return (component);
};

const visibleIf = (conditions, values) => {
  return (conditions || []).some(condition => {
    switch (condition.compare) {
      case COMPARE_ENTITIES.FIELD_VALUE:
        return compareFieldValue(values, condition.target, condition.equals);

      default: return false;
    }
  });
};

const compareFieldValue = (values, fieldName, targetValue) => {
  return values[fieldName] === targetValue;
};
