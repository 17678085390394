import actionCreator from '@/helpers/redux/actionCreator';

export const UPDATE_FORM = '@/UPDATE_FORM';
export const SAVE_FORM_UPDATES = '@/SAVE_FORM_UPDATES';
export const UPDATE_FORM_REQUEST = '@/UPDATE_FORM_REQUEST';
export const UPDATE_FORM_RESPONSE = '@/UPDATE_FORM_RESPONSE';
export const UPDATE_FORM_RESPONSE_FAIL = '@/UPDATE_FORM_RESPONSE_FAIL';
export const UPDATE_BEFORE_CREATE = '@/UPDATE_BEFORE_CREATE';

export const updateForm = () => actionCreator(UPDATE_FORM);

export const updateBeforeCreate = id => actionCreator(UPDATE_BEFORE_CREATE, { id });

export const saveFormUpdates = () => actionCreator(SAVE_FORM_UPDATES);

export const updateFormRequest = (id, form) => actionCreator(UPDATE_FORM_REQUEST, { id, form });

export const updateFormResponse = () => actionCreator(UPDATE_FORM_RESPONSE);

export const updateFormResponseFail = error => actionCreator(UPDATE_FORM_RESPONSE_FAIL, { error });
