import React from 'react';
import PropTypes from 'prop-types';

import { Box, spacing } from 'elemental-ui';
import {Button} from "@/components/blocks/Button"

class FormActionsCancel extends React.Component {
  cancelHandle = () => {
    const { cancelSubmit } = this.props;
    cancelSubmit('/admin-panel/forms-list');
  }

  render() {
    return (
      <Box
        marginBottom={spacing.s}
        marginTop={spacing.s}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="warning"
          marginRight={spacing.xxs}
          onClick={this.cancelHandle}
        >
          Cancel
        </Button>
      </Box>
    );
  }
}

FormActionsCancel.propTypes = {
  cancelSubmit: PropTypes.func.isRequired,
};

export default FormActionsCancel;
