import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import form from './form';
import selectPeople from './selectPeople';
import user from './user';
import modalWindow from './modalWindow';
import getFormsList from './getFormsList';
import getForm from './getForm';
import updateForm from './updateForm';
import addedEntityNotification from './addedEntityNotification';
import getUser from './getUser';
import getUserList from './getUserList';
import contactPersonField from './contactPersonField';
import createForm from './createForm';
import checkUserGroups from './checkUserGroups';
import redirect from './redirect';

export default history => combineReducers({
  router: connectRouter(history),
  form,
  selectPeople,
  user,
  modalWindow,
  getFormsList,
  getForm,
  updateForm,
  addedEntityNotification,
  getUser,
  getUserList,
  contactPersonField,
  createForm,
  checkUserGroups,
  redirect,
});
