import actionCreator from '@/helpers/redux/actionCreator';

export const GET_FORMS_LIST_REQUEST = '@/GET_FORMS_LIST_REQUEST';
export const GET_FORMS_LIST_RESPONSE = '@/GET_FORMS_LIST_RESPONSE';
export const GET_FORMS_LIST_RESPONSE_FAIL = '@/GET_FORMS_LIST_RESPONSE_FAIL';
export const RESET_PAGINATION = '@/RESET_PAGINATION';
export const SET_PAGINATION_PAGE = '@/SET_PAGINATION_PAGE';

export const getFormsListRequest = () => actionCreator(GET_FORMS_LIST_REQUEST);

export const getFormsListResponse = formsList => actionCreator(GET_FORMS_LIST_RESPONSE, {
  formsList,
});

export const getFormsListResponseFail = error => actionCreator(GET_FORMS_LIST_RESPONSE_FAIL, {
  error,
});

export const resetPagination = () => actionCreator(RESET_PAGINATION);
export const setPaginationPage = page => actionCreator(SET_PAGINATION_PAGE, { page });
