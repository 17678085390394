const SUBMITTING = 0;
const SUBMITTED = 1;
const SUBMISSION_FAILED = 2;
const WAITING_FOR_SUBMISSION = 3;

export const SubmissionStates = {
    SUBMITTING,
    SUBMITTED,
    SUBMISSION_FAILED,
    WAITING_FOR_SUBMISSION
}