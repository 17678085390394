import {
  GET_USERLIST_REQUEST,
  GET_USERLIST_RESPONSE,
  GET_USERLIST_RESPONSE_FAIL,
} from '@/actions';

const initialState = {
  requested: false,
  isRequested: false,
  user: {},
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERLIST_REQUEST:
      return {
        ...state,
        requested: true,
        isRequested: false,
        error: '',
        user: {},
      };
    case GET_USERLIST_RESPONSE:
      return {
        ...state,
        user: action.payload.user,
        isRequested: true,
        error: '',
      };
    case GET_USERLIST_RESPONSE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        requested: false,
        isRequested: false,
        user: {},
      };
    default:
      return state;
  }
}
