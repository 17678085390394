import {
  EDIT_FIELD_CLOSE,
  EDIT_FIELD_OPEN,
} from '@/actions';

const initialState = {
  editFieldOpened: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_FIELD_OPEN:
      return {
        ...state,
        editFieldOpened: true,
      };
    case EDIT_FIELD_CLOSE:
      return {
        ...state,
        editFieldOpened: false,
      };
    default:
      return state;
  }
}
