import styled from 'styled-components'

const TEXT_SIZES = {
  h1: 26,
  h2: 17,
  normal: 14
}

const TEXT_BOLDNESS = {
  h1: 700,
  normal: 400
}

export const WarningText = styled.span`
  color: ${props => props.theme.text.warning};
`

/**
 * Variants: default, neutral & warning
 */
export const Text = styled.span`
  color: ${props => props.theme.text[props.variant] || props.theme.text.default};
  font-weight: ${({variant}) => TEXT_BOLDNESS[variant] || 'normal'};
  font-size: ${({variant}) => `${TEXT_SIZES[variant]}px`};
`


