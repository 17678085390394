import { compose } from 'redux';
import { connect } from 'react-redux';

import AuthenticationEndPointPage from './component';

const mapStateToProps = () => ({
});
const mapDispatchToProps = ({
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AuthenticationEndPointPage);
