/**
 * Extracts form data and wraps it for Fortum service
 *
 * @param {object} values - Formik values object
 * @param {object} labels - Field labels
 * @param {boolean | undefined} ignoreNullFields - does not include fields with empty values
 */
export const extractFormikFields = (values, labels, ignoreNullFields = false) => {
  var multis = {};
  var formerValues = {};
  var multiTitles = [];
  var acc = [];

  Object.keys(values).map((key, idx) => {
    if (values[key] === true || values[key] === false) {
      var [realName, valueName] = key.split("-----");
      if (!valueName && values[key] === false) valueName = "No";
      if (!valueName && values[key] === true) valueName = "Yes";
      if (multis[realName] === undefined) {
        multis[realName] = idx;
      }
      formerValues[realName] = (acc[multis[realName]] && acc[multis[realName]].value) ? acc[multis[realName]].value : false;
      acc[multis[realName]] = {
        label: labels[realName],
        name: realName,
        value: (!!formerValues[realName] ? (formerValues[realName] + ', ' + valueName) : valueName),
      }
    } else if (!(ignoreNullFields && !values[key]) || values[key] === "") {
      multis[key] = idx;
      acc[idx] = {
        label: labels[key],
        name: key,
        value: acc[key] ? acc[key].value : values[key],
      }
    }
  });
  console.log(acc);
  return acc;
}

export const extractFieldLabels = (formConfiguration) => {
  const { fields, fieldsSecond } = formConfiguration;
  return (fieldsSecond ? fields.concat(fieldsSecond) : fields).reduce((acc, { name, label }) => ({ ...acc, [name]: label }), {});
};
