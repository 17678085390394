import {
  SUBMIT_FORM_REQUEST,
  SUBMIT_FORM_RESPONSE,
  SUBMIT_FORM_RESPONSE_FAIL,
  NEXT_FORM_STAGE,
  SAVE_FORM,
  CANCEL_SUBMIT_FORM,
  SAVE_FORMIK_VALUES,
} from '@/actions';

import { SubmissionStates } from '@/constants'

const initialState = {
  isSubmitting: false,
  submissionState: SubmissionStates.WAITING_FOR_SUBMISSION,
  error: null,
  checkNextStage: true,
  saved: false,
  cancel: false,
  formikValues: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBMIT_FORM_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        submissionState: SubmissionStates.SUBMITTING,
        error: null,
      };
    case SUBMIT_FORM_RESPONSE:
      return { ...state, isSubmitting: false, submissionState: SubmissionStates.SUBMITTED };
    case SUBMIT_FORM_RESPONSE_FAIL:
      return {
        ...state,
        isSubmitting: false,
        submissionState: SubmissionStates.SUBMISSION_FAILED,
        error: action.payload.error,
      };
    case NEXT_FORM_STAGE:
      return {
        ...state,
        checkNextStage: false,
      };
    case SAVE_FORM:
      return { ...state, saved: true };
    case CANCEL_SUBMIT_FORM:
      return { ...state, cancel: true };
    case SAVE_FORMIK_VALUES:
      return { ...state, formikValues: action.payload.formikValues };
    default:
      return state;
  }
}
