import * as Msal from "msal";

const tenantId = process.env.REACT_APP_AZURE_AD_TENANT_ID;
const clientId = process.env.REACT_APP_AZURE_APP_ID;
const authority = `https://login.microsoftonline.com/${tenantId}`;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const postLogoutRedirectUri = process.env.REACT_APP_LOGOUT_URI;
const onTokenReceived = (errorDescription, token, error, tokenType) => {
  if (token) {
   // console.log("Token has been received");
  } else {
   // console.log("Error:", error, ";", errorDescription);
  }
};

export const userAgentApplication = new Msal.UserAgentApplication(
  clientId,
  authority,
  function(errorDescription, token, error, tokenType) {},
  {
    postLogoutRedirectUri: postLogoutRedirectUri,
    redirectUri: redirectUri,
    navigateToLoginRequestUrl: false
  },
  onTokenReceived,
  {
    storeAuthStateInCookie: true
  }
);
