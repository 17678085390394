import { connect } from 'react-redux';

import FormActionsCancel from './component';
import { cancelSubmit } from '@/actions';

const mapStateToProps = null;

const mapDispatchToProps = {
  cancelSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormActionsCancel);
