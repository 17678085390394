import { compose } from 'redux';
import { connect } from 'react-redux';

import TableView from './component';
import { setPaginationPage } from '@/actions';

const mapStateToProps = state => ({
  activePaginationPage: state.getFormsList.activePaginationPage,
  countPaginationPage: state.getFormsList.countPaginationPage,
  formsList: state.getFormsList.formsList,
});

const mapDispatchToProps = ({
  setPaginationPage,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TableView);
