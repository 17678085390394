import { connect } from 'react-redux';

import ErrorMessage from './component';

const mapStateToProps = state => ({
  errorText: state.form.error,
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorMessage);
