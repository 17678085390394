
import styled from "styled-components"
import Select from 'react-select'
import { DatePicker } from "elemental-ui"
import IconCheck from "@/assets/check.png"
import { Icon } from "@/components/forms/SelectPeople/styles"

export const MSPDatePicker = styled(DatePicker)`
    .react-datepicker__input-container {   
        font-family: inherit;   
        input {
            background-color: ${props => (props.disabled ? props.theme.disabled : props.theme.neutral)};
            color: ${props => props.theme.text.neutral};
            font-size: 13px;
            font-family:inherit;
            border: none;
            padding: 0px;
            padding-left: 1em;
            height: 38px;
        }

        button {
            color: ${props => props.theme.neutraltext} !important;
            top: 0.25rem;
        }

        label {
            display: none;
            font-family:inherit;
            
        }

       
    }

    .react-datepicker__day--keyboard-selected {
        background-color: ${props => props.theme.main};
    }
    .react-datepicker__day--today {
        background-color: ${props => props.theme.text.neutral};
    }
`

/** BASE INPUT RELATED STYLES */

export const BaseInput = styled.input`
    background-color: transparent !important;
    color: ${props => props.theme.neutraltext};
    border: none;
    width: 100% !important;

    :focus {
        border: none;
    }

    :focus-visible {
        outline: none;
        background-color: transparent;
    }

    ::placeholder{
        color: ${props => props.theme.text.neutral};
        font-size: 13px;
    }
`

export const BaseInputContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: ${props => (props.disabled ? props.theme.disabled : props.theme.neutral)};
    color: ${props => props.theme.neutraltext};
    display: block;
`

export const Label = styled.span`
    color: ${props => props.theme.text.default};
    margin-left: 5px;
    margin-right: 5px;
`

export const Container = styled.div`

`

export const SelectInput = styled(Select)`
    width: 100%;

    pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
    .select__placeholder {
        color: ${props => props.theme.text.neutral};
        font-size: 13px;
    }
    .select__control--menu-is-open {
        border: none;
    }

    .select__control--is-focused {
        
    }

    .select__control {
        background-color: ${props => props.theme.input.background};
        
        color: ${props => props.theme.text.neutral};
        border: none !important;
        box-shadow: none !important;
        width: 100%;
        border-radius: 0px;
        pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
    }


    .select__control:hover {
        border: none;
        box-shadow: none;
    }

    .select__indicator-separator {
        visibility: hidden;
    }

    .select__indicator {
        color: grey;
    }

    .select__menu {
        margin-top: 0px;
        border-radius: 0px;
        border: none;
        box-shadow: none;
        width: 100%;
        background-color: ${props => props.theme.input.background};
        color: ${props => props.theme.text.neutral};
    }

 
    .select__option--is-selected {
        color: ${props => props.theme.text.neutral};
        font-size: 13px;
    }

    .select__option {
        background-color: ${props => props.theme.input.background};
        color: ${props => props.theme.text.neutral};
        font-size: 13px;
        :hover {
            background-color: ${props => props.theme.input.contrast};
        }
    }

    .select__single-value {
        color: ${props => props.theme.text.neutral};
        font-size: 13px;
    }
`

export const SelectContainer = styled(BaseInputContainer)`
    display: flex;
    flex-shrink: 1;
`

/** CHECKBOX RELATED STYLES */

export const CheckBoxInput = styled.input`
    margin: 0px;
    width: 100%;
    height: 100%;
`

/*

    As a temporary measure checkbox input has been hidden and stretched to fill the container.

*/

export const CheckBoxContainer = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin-top: .4em;
        margin-bottom: 2px;    

        input[type="checkbox"] {
            appearance: none;
            background-color: transparent;
            color: ${props => props.theme.main};
            border: 1px solid;
            border-color: ${props => props.theme.main};
            background-clip: initial;

            height: 22px;
            width: 22px;


            @keyframes fillCheck {
                from {
                    background-color: 'transparent';
                }

                to {
                    background-color: ${props => props.theme.main};
                }
            }

            :checked {
                background-color: ${props => props.theme.main};
                background-image: url(${IconCheck});
                background-size: 50%;
                background-repeat: no-repeat;
                background-position: center;
                animation-name: ${props => (!props.disabled && 'fillCheck')};
                animation-duration: 0.2s;
                animation-timing-function: linear;
            }
        }
`

export const CheckboxLabel = styled(Label)`
    margin-left: 15px;
`

/** RADIOBUTTON RELATED STYLES */

export const RadioButtonContainer = styled.div`
    padding: 1px;
    color: ${props => props.theme.main};
    background-color: #fff;
    border: solid;
    border-width: 1px;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    @keyframes selection {
        from {
            color: "grey"
        }

        to {
            color: ${props => props.theme.main};
        }
    }

    div {
        background-color: ${props => props.theme.main};
        height: ${props => (props.selected ? 11 : 0)}px;
        width: ${props => (props.selected ? 11 : 0)}px;;
        animation-name: ${props => (props.selected ? 'fill' : 'fillReverse')};
        animation-duration: 0.1s;
        animation-timing-function: linear;

        @keyframes fill {
            from {
                height: 0px;
                width: 0px;
            }

            to {
                height: 11px;
                width: 11px;
            }
        }

    @keyframes fillReverse {
        from {
            height: 11px;
            width: 11px;
            
        }
        to {
            height: 0px;
            width: 0px;
        }
    }
    }
`

export const RadioButtonCircle = styled.div`
    border-radius: 100%;
`

export const RadioButtonInput = styled.input`
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: ${props => props.theme.background};
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid ${props => props.theme.main};;
    border-radius: 100%;
    transform: translateY(-0.075em);

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        width: 0.383em;
        height: 0.383em;
        border-radius: 100%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${props => props.theme.main};
        /* Windows High Contrast Mode */
        background-color: CanvasText;
        color: ${props => props.theme.neutral};
        border-color: ${props => props.theme.neutral};
    }

    :checked {
        color: ${props => props.theme.neutral};
        &::before {
            transform: scale(1);
        }
    }
  
 
`

export const RadioFormControl = styled.label`
  font-size: 1rem;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 15px;
  margin-top: .4rem;
  color ${props => props.theme.neutral}

  :focus-within {
    color: ${props => props.theme.main}
  }
`

/** TEXTAREA RELATED STYLES */

export const TextAreaInput = styled(BaseInput)`
    min-height: 100px;
    ::placeholder {
        color: ${props => props.theme.text.neutral};
        font-size: 13px;
    }
`

/** DATE FIELD RELATED STYLES */

export const DateInput = styled.input`
    background-color: transparent;
    color: ${props => props.theme.neutral};
    border: none;

    :focus {
        border: none;
    }

    :focus-visible {
        outline: none;
    }

    ::-webkit-calendar-picker-indicator { 
        height: 25px;
        width: 25px;
        color: ${props => props.theme.neutral}
    }

    ::-webkit-datetime-edit {
        display: none;
    }
`

export const DateInputLabel = styled(Label)`
    background-color: ${props => props.theme.neutral};
    color: ${props => props.theme.neutraltext};
    font-size: 13px;
`

export const DateFieldContainer = styled(BaseInputContainer)`
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
`

export const FileInputLabel = styled.label`

        background-color: ${props => (props.outlined ? 'transparent' : (props.disabled ? props.theme.disabled : props.theme.main))};
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        color: ${props => (props.outlined ? props.theme.main : (props.disabled ? props.theme.neutral : '#fff'))};
        border: solid;
        border-width: 1px;
        border-color: ${props => (props.outlined ? props.theme.main : (props.disabled ? props.theme.neutral : '#fff'))};

        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        margin-right: 1rem;

`

/** FILE UPLOAD FIELD RELATED STYLES */

export const FileInput = styled.input`
    width: 100%;
    display: none;
    ::-webkit-file-upload-button {
        position: relative;

        background-color: ${props => (props.outlined ? 'transparent' : (props.disabled ? props.theme.disabled : props.theme.main))};
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        color: ${props => (props.outlined ? props.theme.main : (props.disabled ? props.theme.neutral : '#fff'))};
        border: solid;
        border-width: 1px;
        border-color: ${props => (props.outlined ? props.theme.main : (props.disabled ? props.theme.neutral : '#fff'))};

        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        margin-right: 1rem;

        :after {
            content: "";
            background-color: ${props => props.theme.disabled};
            display: block;
            position: absolute;
            padding-top: 300%;
            padding-left: 350%;
            margin-left: -2rem!important;
            margin-top: -120%;
            opacity: 0;
            transition: all 0.8s
        }

        :active {
            :after {
            padding: 0;
            margin: 0;
            opacity: 1;
            transition: 0s
        }

        }

        :hover {
            cursor: pointer;
        } 
    }
`

export const FileUploadContainer = styled(BaseInputContainer)`
    padding: 0px;
    background-color: transparent;
`