export const ACCESS_RIGHTS_SHEET_FIELD = 'accessRightsSheet';
export const ADDITIONAL_INFORMATION_FIELD = 'additionalInformation';
export const ASIAN_OPTION_FIELD = 'asianOption';
export const LA_CODE_FIELD = 'laCode';
export const AD_ACCOUNT_ID_FIELD = 'adAccountId';
export const ADVERTISED_CHECK_FIELD = 'advertisedCheck';
export const APPLICATION_NAME_FIELD = 'applicationName';
export const APPLICATION_OWNER_FIELD = 'applicationOwner';
export const APPLICATION_TICKETS_AVAILABILITY = 'applicationTicketsAvailability';
export const APPLICATION_SHOULD_BE_AVAILABLE_FIELD = 'applicationShouldBeAvailable';
export const APPLICATION_SUPPORT_VENDOR_FIELD = 'applicationSupportVendor';
export const APPLICATION_TYPE_FIELD = 'applicationType';
export const ARE_YOUT_OR_THE_USER_PURCHASING_MANAGER_FIELD = 'areYouOrTheUserPurchasingManager';
export const ALL_RELEVANT_STAKEHOLDERS_INFORMED_FIELD = 'allRelevantStakeholdersInformed';
export const ARCHIVE_CUSTOMER_NUMBER_FIELD = 'archiveCustomerNumber';
export const BRANCH_COUNTRY_FIELD = 'branchCountry';
export const BRANCH_OPENED_FOR_FIELD = 'openedFor';
export const BRANCH_OPERATION_FIELD = 'createNewOrUpdateExistingBranch';
export const BROWSER_FIELD = 'browser';
export const BUDGET_FORECAST_FIELD = 'budgetForecast';
export const BUSINESS_COSTS_HANDLING_FIELD = 'businessCostsHandling';
export const BUSINESS_OWNERSHIP_FIELD = 'businessOwnership';
export const BUSINESS_OWNER_FIELD = 'businessOwner';
export const CATEGORY_FIELD = 'category';
export const CONTACT_PERSON_IF_NOT_THE_REQUESTOR_FIELD = 'contactPersonIfNotRequestor';
export const COSTS_FIELD = 'costsField';
export const COST_FORECAST_IN_ARS_FIELD = 'costForecastInARS';
export const DESCRIPTION_OF_THE_REQUEST = 'descriptionOfTheRequest';
export const CCB_ADDED_FIELD = 'ccbAdded';
export const CONTACT_PERSON_FIELD = 'contactPerson';
export const CHAT_GROUP_MEMBERS_FIELD = 'chatGroupMembers';
export const CONTACT_USER_ID_FIELD = 'contactUserId';
export const CHANGE_FOR_THE_CURRENT_SERVER = 'changeForTheCurrentServer';
export const COUNTRIES_WHERE_IN_USE_FIELD = 'countiesWhereInUse';
export const CURRENT_PRO_CAPABILITY_FIELD = 'currentPROCapability';
export const CURRENT_RTO_CAPABILITY_FIELD = 'currentRTOcapability';
export const DOMAIN_FIELD = 'domain';
export const DATA_BASE_TYPE = 'dataBaseType';
export const DATA_LOCATION_FIELD = 'dataLocation';
export const DATA_LOSS_TOLERANCE_BEFORE_FIELD = 'dataLossToleranceBefore';
export const DOWNTIME_TOLERANCE_BEFORE_FIELD = 'downtimeToleranceBefore';
export const DATASOURCE_WHERE_ACCESS_REQUESTED = 'datasourceWhereAccessRequested';
export const CLIENT_ID_FIELD = 'clientId';
export const EBOK_LOGIN_FIELD = 'ebokLogin';
export const EMAIL_ADDRESS_FIELD = 'emailAddress';
export const ENVIROMENTS_FIELD = 'enviroments';
export const EMPLOYEE_ID_FIELD = 'employeeId';
export const EMPLOYMENT_CASE_FIELD = 'employmentCase';
export const EUROPEAN_OPTION_FIELD = 'europeanOption';
export const ENVIRONMENT = 'environment';
export const FBS_IT_COSTS_HANDLING_FIELD = 'FBSITCostsHandling';
export const FBS_IT_PARTNER_CODE_FIELD = 'FBSITPartnerCode';
export const FILE_UPLOAD_FIELD = 'fileUpload';
export const FILE_UPLOAD_SIZE = 'fileUploadSize';
export const FINA_ID_FIELD = 'FINAId';
export const FBS_IT_EXTERNAL_COSTS_FIELD = 'FBSExternalCosts';
export const FBS_IT_RESPONSE_CODE_FIELD = 'FBSResponseCode';
export const FBS_IT_SECURITY_OFFICER_FIELD = 'FBSITSecurityOfficer';
export const FBS_IT_SECURITY_OFFICER_INFORMED_FIELD = 'FBSITSecurityOfficerInformed';
export const FIREWALL_OPENINGS_FIELD = 'firewallOpenings';
export const FINA_PROJECT_CODE_FIELD = 'FINAProjectCode';
export const FIRST_NAME_FIELD = 'firstName';
export const FOR_WHOM_ACCESS_REQUESTED_FIELD = 'forWhomAccessRequested';
export const FOR_WHOM_ACCESS_REQUESTED_INPUT_FIELD = 'forWhomAccessRequestedInput';
export const FORTUM_EMPLOYEES_AMOUNT_OF_WORK_FIELD = 'fortumEmployeesAmountOfWork';
export const FORTUM_EXTERNAL_COSTS_FIELD = 'fortumExternalCosts';
export const FORTUM_INVESTMENT_OR_PROJECT_CATEGORY_FIELD = 'fortumInvestmentOrProjectCategory';
export const FROM_WICH_PAY_PERIOD_THE_CHANGE_IS_VALID_FIELD = 'fromWichPayPeriodTheChangeIsValidField';
export const FULL_PROBLEM_DESCRIPTION_FIELD = 'fullProblemDesc';
export const FULL_TIME_PART_TIME_FIELD = 'FULL_TIME_PART_TIME_FIELD';
export const HDEF_LOGIN_EMAIL_FIELD = 'hdefLoginEmail';
export const HOURS_FIELD = 'hours';
export const HOURLY_RATE_FIELD = 'hourlyRateForInternalCostAllocation';
export const INFRA_SERVICES_FIELD = 'infraServices';
export const ITSM_TICKETS_HAVE_BEEN_CLOSED_FIELD = 'itsmTicketsHaveBeenClosed';
export const INTEGRATIONS_HAVE_BEEN_CLOSED_FIELD = 'integrationsHaveBeenClosed';
export const ITSM_DEFAULT_TEAM_FIELD = 'itsmDefaultTeam';
export const ITSM_TEAM_CONFIGURATIONS_FIELD = 'itsmTeamConfiguration';
export const IF_PREV_IS_TRUE_FIELD = 'ifPrevIsTrue';
export const INFORMATION_ABOUT_NEW_SOFTWARE_FIELD = 'informationAboutNewSoftware';
export const IT_OWNERSHIP_MANAGER_CLASSIFICATION_FIELD = 'ITOwnershipManagerClassification';
export const IT_OWNERSHIP_UNDER_FBS_IT_FIELD = 'ITOwnershipUnderFBSIT';
export const IT_OWNER_FIELD = 'ITOwner';
export const LAST_NAME_FIELD = 'lastName';
export const LANGUAGE_FIELD = 'language';
export const LOCATION_OF_INSTALLATION_MEDIA_FIELD = 'locationOfInstallationMedia';
export const LA_CARRYING_THE_COSTS_IF_NOT_THE_PREVIOUS_FIELD = 'laCarryingTheCostsIfNotThePrevious';
export const LA_CODE_FOR_THE_REQUEST_FIELD = 'laCodeForTheRequest';
export const LA_CODE_NEEDED_FOR_FIELD = 'LACodeNeededFor';
export const LA_CODE_FOR_THE_APPLICATION_NEEDING_THE_CLOUD_RESOURCES_FIELD = 'laCodeForTheApplicationNeedingTheCloudResources';
export const LA_CODE_FOR_WNER_APPLICATION = 'LAcodeForOwnerApplication';
export const LA_DEFINITION_FIELD = 'LADefinition';
export const LA_DESCRIPTION_FIELD = 'LADescriptionField';
export const LA_NAME_FIELD = 'LAName';
export const LA_OUTCOME_OF_EXISTING_CASE_OR_PROJECT_FIELD = 'LAOutcomeOfExistingCaseOrProject';
export const LIFECYCLE_PHASE_FIELD = 'lifecyclePhase';
export const LICENSE_AGREEMENTS_HAVE_BEEN_TERMINATED_FIELD = 'licenseAgrementsHaveBeenTerminated';
export const MAIN_BUSINESS_DOMAIN_FIELD = 'mainBusinessDomain';
export const MAIN_BUSINESS_FIELD = 'mainBusiness';
export const MANAGER_USER_ID_FIELD = 'managerUserId';
export const MOJE_FORTUM_LOGIN_EMAIL_FIELD = 'mojeFortumLoginEmail';
export const MANUFACTURER_FIELD = 'manufacturer';
export const MINUTES_FIELD = 'minutes';
export const MOB_APP_VERSION_FIELD = 'mobAppVersion';
export const NAME_OF_THE_EMPLOYEE_FIELD = 'nameOfTheEmployee';
export const NAME_OF_SITES_FIELD = 'nameOfSities';
export const NEW_IT_SERVICE_NAME_FIELD = 'newITServiceName';
export const NEW_LIFECYCLE_PHASE_FIELD = 'newLifecyclePhase';
export const NUMBER_OFESTIMATED_USERS_FIELD = 'numberOfestimatedUsers';
export const OFFICE_LOCATION_FIELD = 'officeLocation';
export const PAWE_CHECKBOX_FIELD = 'paweCheckbox';
export const PAYER_LA_CODE_FIELD = 'payerLaCode';
export const OPERATING_SYSTEM = 'operatingSystem';
export const OTHER_INFORMATION = 'otherInformation';
export const OPERATING_SYSTEM_POLAND_FIELD = 'operatingSystemPoland';
export const OPERATING_SYSTEM_MOB_FIELD = 'opratingSystemMob';
export const PAYER_FUNCTION_CODE_FIELD = 'payerFunctionCode';
export const PAYER_PARTNER_CODE_FIELD = 'payerPartnerCode';
export const PAYER_PROJECT_CODE_FIELD = 'payerProjectCode';
export const PAYER_PURPOSE_CODE_FIELD = 'payerPurposeCode';
export const PAYER_RESPONSE_CODE_FIELD = 'payerResponseCode';
export const PAYMENT_TERM_FIELD = 'paymentTerm';
export const PEAK_FIELD = 'peak';
export const PEOPLE_HANDLING_TICKETS_FIELD = 'peopleHandlingTickets';
export const PREFERED_CLOUD_FIELD = 'preferedCloud';
export const PRIMATY_SYSTEM_MANAGER_FIELD = 'primarySystemManager';
export const PRIVACY_REQUIREMENTS_FIELD = 'privacyRequirements';
export const PRIVACY_SHEET_FIELD = 'privacySheet';
export const PERMISSION_TO_ADD_CONTRACTS_AND_VIEW_THE_LIST_OF_ALL_CONTRACTS_FIELD =
'permissionToAddContractsAndViewTheListOfAllContracts';
export const PERMISSION_TO_DETAILED_INFORMATION_OF_CONTRACTS_FIELD =
'permissiongToDetailedInformationOfContracts';
export const PROBLEM_DATE_AND_TIME_FIELD = 'problemDateAndTime';
export const PRODUCT_TECHNOLOGY_FIELD = 'productTechnology';
export const PRODUCT_TECHNOLOGY_SUPPORT_VENDOR_FIELD = 'productTechnologySupportVendor';
export const PRODUCT_TECHNOLOGY_OWNER_FIELD = 'productTechnologyOwner';
export const PRODUCT_FIELD = 'productField';
export const PROJECT_MANAGER_FIELD = 'projectManager';
export const PROJECT_OR_CASE_DESCRIPTION_FIELD = 'projectOrCaseDescription';
export const PROJECT_OR_CASE_ESTIMATED_COMPLETION_DATE_FIELD = 'projectOrCaseEstimatedCompletionDate';
export const PROJECT_OR_CASE_NAME_FIELD = 'projectOrCaseName';
export const PROJECT_OR_CASE_PHASE_FIELD = 'projectOrCasePhase';
export const PROJECT_OR_CASE_PURPOSE_FIELD = 'projectOrCasePurpose';
export const PROJECT_OR_CASE_START_DATE_FIELD = 'projectOrCaseStartDate';
export const PROJECT_OWNER_FIELD = 'projectOwner';
export const PURPOSE_FIELD = 'purpose';
export const QUESTIONAIRE_TO_BE_SENT_FIELD = 'questionaireToBeSent';
export const REQUEST_DELIVERY_FIELD = 'requestDelivery';
export const REQUESTED_QUALIFICATION_DATE_FIELD = 'requestedQualificationDate';
export const RPA_PROCESS_FIELD = 'rpaProcess';
export const SEARCH_FIELD = 'search';
export const SERVICE_LEVEL = 'serviceLevel';
export const SERVER_NAMES_FOR_THE_CHANGE = 'serverNamesForTheChange';
export const SIZE = 'size';
export const SHEETS_UNDER_OWNERSHIPS_IN_THE_CMDB_FIELD = 'sheetsUnderOwnershipsInTheCMDB';
export const SHORT_PROBLEM_DESCRIPTION_FIELD = 'shortProblemDesc';
export const SERVICE_CONTINUITY_SHEET_FIELD = 'serviceContinuitySheet';
export const SECURITY_REQUIREMENTS_FIELD = 'securityRequirements';
export const SUPPORT_TIME_EET_FIELD = 'supportTimeEetField';
export const SOFTWARE_RELATED_TO_THE_APPLICATION_FIELD = 'softwareRelataderToTheApplication';
export const SOFTWARE_VERSION = 'softwareVersion';
export const SUPPLIER_ADDITIONAL_INFORMATION = 'additionalInformation';
export const SUPPLIER_ADDRESS_FIELD = 'supplierAddress';
export const SUPPLIER_CITY_FIELD = 'city';
export const SUPPLIER_CONTACT_EMAIL_FIELD = 'supplierContactEmail';
export const SUPPLIER_CONTACT_PERSON_FIELD = 'supplierContactPerson';
export const SUPPLIER_COUNTRY_FIELD = 'country';
export const SUPPLIER_NAME_FIELD = 'supplierName';
export const SUPPLIER_NUMBER_FIELD = 'supplierNumber';
export const SUPPLIER_OPENED_TO_FIELD = 'openedTo';
export const SUPPLIER_POSTAL_CODE_FIELD = 'postalCode';
export const TAX_PAYER_ID_FIELD = 'taxPayerId';
export const TICKET_IN_ARS_FIELD = 'ticketInArs';
export const USER_ACCOUNT_REQUIRING_ACCESS_FIELD = 'userAccountRequiringAccess';
export const USER_GROUP_FIELD = 'userGroup';
export const USER_NAME_OF_A_COLLEAGUE_FIELD = 'userNameOfAColleague';
export const VAT_REGISTRATION_NUMBER_FIELD = 'vat';
export const BROWSER_VERSION_FIELD = 'browserVersion';
export const VIEW_FIELD = 'view';
export const WEEKLY_FULL_TIME_WORKING_HOURS_FIELD = 'weeklyFullTimeWorkingHours';
export const WHAT_DO_YOU_WANT_TO_DO_FIELD = 'whatDoYouWandToDo';
export const WHAT_TO_DO_WITH_DATA_FIELD = 'whatToDoWithData';
export const WHAT_KIND_OF_ACCESS_FIELD = 'whatKindOfAccess';
export const WHAT_KIND_OF_ACCESS_FIELD_INPUT_FIELD = 'whatKindOfAccessInput';
export const VIRTUAL_SERVER_ROLE = 'virtualServerRole';
export const VENDOR_CONTRACTS_HAVE_BEEN_TERMINATED_FIELD = 'vendorContractsHaveBeenTerminated';
