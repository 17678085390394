import { FORTUM_FORMS_BACKEND_ENDPOINT } from '@/constants';
import md5 from 'md5';

export const getFormById = id => fetch(`${FORTUM_FORMS_BACKEND_ENDPOINT}/api/forms/${id}`, {
  method: 'GET',
  headers: {
    'Access-Control-Allow-Origin': FORTUM_FORMS_BACKEND_ENDPOINT,
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Content-Type': 'application/json',
  },
}).then(res => res.json()).then(resultForm => {
  let res = resultForm;
  resultForm.formConfiguration.fields.map((field, idx) => {
    res.formConfiguration.fields[idx] = {...field, name: (field.component === 'FileUploader') ? field.name : md5(field.name)};
  });
  return res;
});

export const getFormByHRef = href => fetch(`${FORTUM_FORMS_BACKEND_ENDPOINT}/api/form-href/${href}`, {
  method: 'GET',
  headers: {
    'Access-Control-Allow-Origin': FORTUM_FORMS_BACKEND_ENDPOINT,
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Content-Type': 'application/json',
  },
}).then(res => res.json()).then(resultForm => {
  let res = resultForm;
  resultForm.formConfiguration.fields.map((field, idx) => {
    res.formConfiguration.fields[idx] = {...field, name: (field.component === 'FileUploader') ? field.name : md5(field.name)};
  });
  return res;
});
