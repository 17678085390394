import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Modal
} from 'elemental-ui';
import { Formik } from 'formik';
import { formFieldsProps } from '@/helpers/mappers/formFieldsProps';
import { extractField, COMPARE_ENTITIES } from '@/helpers/form/fieldExtractor';
import { Button } from "@/components/blocks/Button"


class ModalWindow extends React.PureComponent {
  handleFormSubmit = (values) => {
    const {
      saveElement, selectElementIndex, editFieldClose,
    } = this.props;
    const visibleIf = [];
    visibleIf.push(
      {
        compare: COMPARE_ENTITIES.FIELD_VALUE,
        target: values.target,
        equals: (values.equals === 'true' ? true : values.equals === 'false' ? false : values.equals),
      },
    );
    if (values.target) Object.assign(values, { visibleIf });

    saveElement(values, selectElementIndex);
    editFieldClose();
  }

  renderForm = ({
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  }) => {
    const { isSubmitting, element, deleteElement, selectElementIndex } = this.props;
    if (element.items) {
      const itemsLabels = element.items.map(item => (element.component === 'Select' ? item.name : item.label));
      element.itemsStr = itemsLabels.join('\n');
    }
    const fields = formFieldsProps(element.component, element.items);
    return (
      <form
        onSubmit={handleSubmit}
        onDragOver={e => this.onDragOver(e)}
        onDrop={e => this.onDropForm(e)}
        style={{ textAlign: 'left' }}
      >
        <Row>
          {fields.map(field => extractField({
            field,
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue
          }))}
        </Row>
        <Row>
          <Col l={6}>
            <Button disabled={isSubmitting || !isValid} type="submit">Save</Button>
          </Col>
          <Col l={6}>
            <Button onClick={() => deleteElement(element.id)} type="button">Delete</Button>
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    const {
      editFieldOpened, editFieldClose, element,
    } = this.props;
    return (
      <Modal opened={editFieldOpened} onClose={editFieldClose} title="Edit field">
        <Formik
          initialValues={element}
          onSubmit={this.handleFormSubmit}
        >
          {this.renderForm}
        </Formik>
      </Modal>
    );
  }
}

ModalWindow.propTypes = {
  editFieldOpened: PropTypes.bool.isRequired,
  editFieldClose: PropTypes.func.isRequired,
  saveElement: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  deleteElement: PropTypes.func.isRequired,
  selectElementIndex: PropTypes.number.isRequired,
};
ModalWindow.defaultProps = {};

export default ModalWindow;
