import { FORTUM_FORMS_BACKEND_ENDPOINT } from '@/constants';

export default (form) => {
  fetch(`${FORTUM_FORMS_BACKEND_ENDPOINT}/api/forms`, {
    headers: {
      'Access-Control-Allow-Origin': FORTUM_FORMS_BACKEND_ENDPOINT,
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(form),
  }).then(res => console.log(res));
};
