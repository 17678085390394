import styled from 'styled-components';
import { ContentText } from 'elemental-ui';

export const StyledContentText = styled(ContentText)`
  white-space: pre-line;
  font-size: 14px !important;
  color: ${props => props.theme.text.default};
  font-family: inherit !important;
`;

export const StyledLabelText = styled(ContentText)`
  white-space: pre-line;
  font-size: 17px;
  font-weight: 400;
  color: ${props => props.theme.text.default};
  line-height: 20.57px;
  font-family: inherit !important;
`