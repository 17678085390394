/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Formik } from 'formik';
import { Row } from 'elemental-ui';
import PropTypes from 'prop-types';
import FormActions from '@/components/blocks/FormActions';
import ErrorMessage from '@/components/blocks/ErrorMessage';
import {
  LANDING_PAGE,
} from '@/constants';

import { extractField, automaticValidate } from '@/helpers/form/fieldExtractor';
 
class DynamicForm extends React.PureComponent {

  componentDidMount() {
    const { formIsRequested, getFormRequest, pathname } = this.props;
    const formHRef = pathname.split('/');
    if (!formIsRequested) getFormRequest('href', formHRef[formHRef.length - 1]);
  }
  
  formRef = React.createRef();

  handleFormSubmit = (values) => {
    const { submitFormRequest, userId, form } = this.props;
    const { formConfiguration } = form;
    submitFormRequest({
      formData: values,
      configuration: formConfiguration,
      redirectUrl: LANDING_PAGE,
      userId,
    });
    this.formRef.reset();
  }

  

  enterHandle = (e) => {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') e.preventDefault();
  };

  renderForm = ({
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  }) => {
    const { isSubmitting, cancelSubmit, form, errorText } = this.props;
    const { formConfiguration } = form;
    const { fields } = formConfiguration;
    return (
      <form ref={el => this.formRef = el} onSubmit={handleSubmit} onKeyDown={this.enterHandle}>
        <Row>
          {fields.map((field, idx) => {
          
            return extractField({
              field,
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
            })
          })}
        </Row>
        <FormActions errorText={errorText} isValid={isValid} cancelSubmit={cancelSubmit} isSubmitting={isSubmitting} redirectUrl={LANDING_PAGE} />
        <ErrorMessage />
      </form>
    );
  }

  render() {
    const { form } = this.props;
    const { formConfiguration } = form;
    const { fields } = formConfiguration;
    const initialValues = fields.reduce((finalObject, field) => Object.assign(finalObject, { [field.name]: field.initialValue || '' }), {});
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.handleFormSubmit}
        validate={values => { const valid = automaticValidate(fields, values); return valid;}}
      >
        {this.renderForm}
      </Formik>
    );
  }
}

DynamicForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitFormRequest: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  formIsRequested: PropTypes.bool.isRequired,
  getFormRequest: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  errorText: PropTypes.string,
};

DynamicForm.defaultProps = {
  errorText: ''
};

export default DynamicForm;
