import styled from 'styled-components';
import { Header, Box } from 'elemental-ui';

export const HeaderLogo = styled(Header.LogoWidget)`
  margin-left: 25px;
`;

export const HeaderUserInfo = styled.button`
  font-family: GT-Eesti-Pro-Display, arial, sans-serif;
  background: none;
  border: none;
  font-size: 1rem;
  height: 5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  -webkit-transition: color 300ms;
  transition: color 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
`;

export const HeaderUserNoAvatar = styled.span`
  border-radius: 1.25rem;
  padding: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid black;
  display: flex;
  align-items: center;
`;

export const HeaderUserInfoAvatar = styled.span`
  color: #fff;
  border-radius: 1.25rem;
  padding: 0.5rem;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
`;

export const HeaderUserInfoName = styled.span`
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  @media (max-width: 1025px) {
    display: none;
  }
`;

export const HeaderUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`

export const Arrow = styled.i`
  border: solid #fff;
  border-width: 0 0.5px 0.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -5px;
  @media (max-width: 1025px) {
    display: none;
  }
`;

export const PopoverContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.33;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter';
  color: rgb(67, 115, 181);
  text-decoration: none;
  text-align: left;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  background-color: ${props => props.theme.main};
  height: 60px;
  padding-right: 1em;
  padding-left: 1em;

  color: #fff;
`