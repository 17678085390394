import React from 'react'
import Icon from '@/assets/logo.png'
import {LogoContainer, LogoIcon} from "./styles"

function Logo(props) {
    return (
        <LogoContainer {...props}>
            <LogoIcon src={Icon} alt="logo" />
        </LogoContainer>
    )
}

export default Logo
