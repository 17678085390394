import { compose } from 'redux';
import { connect } from 'react-redux';

import AdminPanelDynamicForm from './component';
import {
  authenticationRequest, editFieldOpen, setFormikParameters, saveFormikValues,
  updateFormRequest, notificationOpen, createFormRequest,
} from '@/actions';

const mapStateToProps = state => ({
  form: state.getForm.form,
  formIsUpdated: state.updateForm.formIsUpdated,
  id: state.updateForm.id,
});
const mapDispatchToProps = ({
  authenticationRequest,
  editFieldOpen,
  setFormikParameters,
  saveFormikValues,
  updateFormRequest,
  notificationOpen,
  createFormRequest,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AdminPanelDynamicForm);
