import { compose } from 'redux';
import { connect } from 'react-redux';

import ModalWindow from './component';
import { editFieldClose } from '@/actions/modalWindow';

const mapStateToProps = state => ({
  editFieldOpened: state.modalWindow.editFieldOpened,
  isSubmitting: state.form.isSubmitting,
});

const mapDispatchToProps = {
  editFieldClose,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ModalWindow);
