import React from 'react';
import { ADMIN_PANEL_ITEMS_LIST } from '@/constants';


class AdminPanelElementsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      elements: ADMIN_PANEL_ITEMS_LIST,
    };
  }

  onDragStart = (ev, name) => {
    ev.dataTransfer.setData('name', name);
  };

  onDragOver = (ev) => {
    ev.preventDefault();
  };

  render() {
    const { elements } = this.state;
    const li = elements.map(element => (
      <li
        key={element.name}
        onDragStart={e => this.onDragStart(e, element.name)}
        draggable
        className="admin-panel__sidebar__item"
      >
        {element.name}
      </li>
    ));
    return (
      <ul
        className="admin-panel__sidebar"
        onDragOver={e => this.onDragOver(e)}
      >
        <span className="admin-panel__sidebar-header">Elements</span>
        {li}
      </ul>
    );
  }
}

AdminPanelElementsList.propTypes = {
};
AdminPanelElementsList.defaultProps = {};

export default AdminPanelElementsList;
