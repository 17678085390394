import PropTypes from 'prop-types';

export const flow3StepsPropType = {
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  data: PropTypes.any,
};

export const flow3StepsDefaultProps = {
  isLoaded: false,
  isLoading: false,
  error: null,
  data: [],
};
