import React from 'react';
import PropTypes from 'prop-types';
import {
  Popover, IconEdit, IconTrash,
} from 'elemental-ui';

import {
  TableBodTd, TablaBodyTdPopoverIcon, EditContainer, EditButton, DeleteButton, DeleteContainer,
  PopoverContainer,
} from './styles';


class TableRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { opened: false };
  }

  handlePopoverOpen = e => this.setState({ opened: true, anchor: e.currentTarget });

  handlePopoverClose = () => this.setState({ opened: false });

  handleDeleteEntity = (id) => {
    const {
      deleteFormRequest,
    } = this.props;
    deleteFormRequest(
      id,
    );
    this.handlePopoverClose();
  }

  handleFormOpen = (requestType, id, href) => {
    const { getFormRequest, updateForm } = this.props;
    updateForm();
    getFormRequest(requestType, id, href);
  }

  render() {
    const {
      label, contentInterfaceID, document_id,
    } = this.props;
    const { opened, anchor } = this.state;
    return (
      <tr>
        <TableBodTd>{label}</TableBodTd>
        <TableBodTd>{contentInterfaceID}</TableBodTd>
        <TablaBodyTdPopoverIcon onClick={this.handlePopoverOpen} />
        <TableBodTd>
          <Popover
            onClose={this.handlePopoverClose}
            opened={opened}
            anchor={anchor}
            anchorPos="topRight"
            backgroundColor="white"
            textColor="black"
          >
            <PopoverContainer>
              <EditContainer onClick={() => this.handleFormOpen('id', document_id, '/admin-panel')}>
                <EditButton invert icon={<IconEdit />} />
                <span>Edit</span>
              </EditContainer>
              <DeleteContainer onClick={() => this.handleDeleteEntity(document_id)}>
                <DeleteButton invert icon={<IconTrash />} />
                <span>Delete</span>
              </DeleteContainer>
            </PopoverContainer>
          </Popover>
        </TableBodTd>
      </tr>
    );
  }
}

TableRow.propTypes = {
  label: PropTypes.string.isRequired,
  document_id: PropTypes.string.isRequired,
  contentInterfaceID: PropTypes.string.isRequired,
  getFormRequest: PropTypes.func.isRequired,
  deleteFormRequest: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default TableRow;
