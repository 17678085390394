import actionCreator from '@/helpers/redux/actionCreator';

export const DELETE_FORM_REQUEST = '@/DELETE_FORM_REQUEST';
export const DELETE_FORM_RESPONSE = '@/DELETE_FORM_RESPONSE';
export const DELETE_FORM_RESPONSE_FAIL = '@/DELETE_FORM_RESPONSE_FAIL';

export const deleteFormRequest = id => actionCreator(DELETE_FORM_REQUEST, { id });

export const deleteFormResponse = () => actionCreator(DELETE_FORM_RESPONSE);

export const deleteFormResponseFail = error => actionCreator(DELETE_FORM_RESPONSE_FAIL, { error });
