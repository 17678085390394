import actionCreator from '@/helpers/redux/actionCreator';

export const CREATE_FORM_REQUEST = '@/CREATE_FORM_REQUEST';
export const CREATE_FORM_RESPONSE = '@/CREATE_FORM_RESPONSE';
export const CREAETE_RESPONSE_FAIL = '@/CREAETE_RESPONSE_FAIL';

export const createFormRequest = (formConfiguration, document_id) => actionCreator(CREATE_FORM_REQUEST, { formConfiguration, document_id });

export const createFormResponse = () => actionCreator(CREATE_FORM_RESPONSE);

export const createFormResponseFail = error => actionCreator(CREAETE_RESPONSE_FAIL, { error });
