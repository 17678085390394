export const dragableItemsMapper = (name) => {
  switch (name) {
    case 'InputField': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'InputField',
      required: false,
      name: 'Input',
      label: 'Label',
    };
    case 'Textarea': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'Textarea',
      required: false,
      name: 'Textarea',
      label: 'Label',
    };
    case 'DatePicker': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'DatePicker',
      name: 'DatePicker',
      label: 'Label',
      required: false,
    };
    case 'RadioButtonGroup': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'RadioButtonGroup',
      required: false,
      name: 'RadioButtonGroup',
      label: 'Service Level',
      items: [
        { label: 'Purchasing', value: 'purchasing' },
        { label: 'Potential', value: 'potential' },
      ],
    };
    case 'Select': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'Select',
      required: false,
      name: 'Select',
      label: 'Godzina',
      items: [
        { name: 'Purchasing', value: 'purchasing' },
        { name: 'Potential', value: 'potential' },
      ],
    };
    case 'CheckboxGroup': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'CheckboxGroup',
      required: false,
      name: 'CheckboxGroup',
      label: 'CheckboxGroup',
      items: [
        { label: 'Purchasing', value: 'purchasing' },
        { label: 'Potential', value: 'potential' },
      ],
    };
    case 'Checkbox': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'Checkbox',
      required: false,
      name: 'name',
      context: 'Checkbox',
      label: '',
    };
    case 'FileUploader': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'FileUploader',
      required: false,
      name: 'fileUpload',
      label: 'Upload file...',
      fileUploadSize: 'fileUploadSize',
    };
    case 'ContactPerson': return {
      id: `f${(+new Date()).toString(16)}`,
      component: 'ContactPerson',
      required: false,
      name: 'ContactPerson',
      label: 'Contact Person',
    };
    default: return (null);
  }
};
