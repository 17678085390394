import React from 'react';
import { PageWrapper } from './styles';

// eslint-disable-next-line react/prop-types
const Page = ({ children }) => (
  <PageWrapper>{children}</PageWrapper>
);


export default Page;
