import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_FORMS_LIST_REQUEST,
  getFormsListResponse,
  getFormsListResponseFail,
} from '@/actions';

import { getFormsList } from '@/api/getFormsList';
import compare from '@/helpers/compare/formsListCompare';

function* getFormsListRequest() {
  try {
    const response = yield call(getFormsList);
    const formsList = response.map(form => Object.assign({}, {
      label: form.formConfiguration.title,
      href: `/${form.href}`,
      document_id: form.document_id,
      contentInterfaceID: form.formConfiguration.contentInterfaceID,
    }));
    yield put(getFormsListResponse(formsList.sort(compare('label'))));
    return true;
  } catch (error) {
    yield put(getFormsListResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(GET_FORMS_LIST_REQUEST, getFormsListRequest);
}
