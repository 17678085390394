import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";

import securedRoute from "@/helpers/router/securedRoute";

import LandingPage from "@/components/pages/LandingPage";
import WorkspacesPage from "@/components/pages/Workspaces";
import AuthenticationPage from "@/components/pages/Authentication";
import AuthenticationEndPointPage from "@/components/pages/AuthenticationEndPointPage";
import NotAuthorizedPage from "@/components/pages/NotAuthorizedPage";
import AdminPanelPage from "@/components/pages/AdminPanelPage";
import AdminPanelFormsListPage from "@/components/pages/AdminPanelFormsListPage";
import {
  LANDING_PAGE,
  AUTHENTICATION_PAGE,
  AUTHENTICATION_END_POINT_PAGE,
  NOT_AUTHORIZED_PAGE,
  ADMIN_PANEL_PAGE,
  ADMIN_PANEL_FORMS_LIST_PAGE,
} from "@/constants";

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route
        path={LANDING_PAGE}
        exact
        component={securedRoute(LandingPage)}
      />

      <Route path={ADMIN_PANEL_FORMS_LIST_PAGE} component={securedRoute(AdminPanelFormsListPage)} />

      <Route path={AUTHENTICATION_PAGE} component={AuthenticationPage} />

      <Route path={NOT_AUTHORIZED_PAGE} component={NotAuthorizedPage} />

      <Route
        path={AUTHENTICATION_END_POINT_PAGE}
        component={AuthenticationEndPointPage}
      />

      <Route
        path={ADMIN_PANEL_PAGE}
        component={securedRoute(AdminPanelPage)}
      />

      <Route
        path={'/*'}
        component={securedRoute(WorkspacesPage)}
      />
    </Switch>
  </ConnectedRouter>
);

export default Router;
