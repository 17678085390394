import { compose } from 'redux';
import { connect } from 'react-redux';

import WorkspacesPage from './component';

const mapStateToProps = state => ({
  formsList: state.getFormsList.formsList,
});
const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(WorkspacesPage);
