import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from "@/components/blocks/Button"
import { NotificationContainer, IconNotification } from './styles';

class Notification extends React.PureComponent {
  handleNotificationClose = () => {
    const { notificationClose } = this.props;
    notificationClose();
  };

  render() {
    return (
      <NotificationContainer>
        <IconNotification />
        <span>Form is saved.</span>
        <IconButton onClick={this.handleNotificationClose} invert icon="IconCross" variant="neutral" />
      </NotificationContainer>
    );
  }
}

Notification.propTypes = {
  notificationClose: PropTypes.func.isRequired,
};
Notification.defaultProps = {};

export default Notification;
