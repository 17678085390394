import { VALIDATORS_OPTIONS } from '@/constants';

export const formFieldsProps = (name, items) => {
  switch (name) {
    case 'InputField': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
        l: 6,
      },
      {
        id: 3,
        component: 'Select',
        required: false,
        name: 'validator',
        label: 'Validator',
        items: VALIDATORS_OPTIONS,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'l',
        label: 'Element size on large screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'm',
        label: 'Element size on middle screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'ContactPerson': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'l',
        label: 'Element size on large screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'm',
        label: 'Element size on middle screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'Textarea': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'DatePicker': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'DatePicker',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'l',
        label: 'Element size on large screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'm',
        label: 'Element size on middle screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'RadioButtonGroup': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'ItemsEdit',
        name: 'itemsStr',
        context: 'Items:',
        items,
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'Select': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'l',
        label: 'Element size on large screen',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'm',
        label: 'Element size on middle screen',
        l: 6,
      },
      {
        component: 'ItemsEdit',
        name: 'itemsStr',
        context: 'Items:',
        items,
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'CheckboxGroup': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
        l: 6,
      },
      {
        component: 'InputField',
        name: 'initialValue',
        label: 'Initial Value',
        l: 6,
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'ItemsEdit',
        name: 'itemsStr',
        context: 'Items:',
        items,
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'Checkbox': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
      },
      {
        component: 'Checkbox',
        name: 'initialValue',
        label: 'Checked',
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    case 'FileUploader': return [
      {
        component: 'InputField',
        required: true,
        name: 'label',
        label: 'Field Label',
      },
      {
        component: 'Checkbox',
        name: 'required',
        label: 'Required',
      },
      {
        component: 'InputField',
        name: 'invalidMessage',
        label: 'Invalid message',
        required: false,
      },
      {
        component: 'InputField',
        name: 'target',
        label: 'Target field',
        required: false,
        l: 6,
      },
      {
        component: 'InputField',
        name: 'equals',
        label: 'Equals value',
        required: false,
        l: 6,
      },
      {
        component: 'Textarea',
        name: 'context',
        label: 'Field Instruction',
        required: false,
      },
    ];
    default: return (null);
  }
};
