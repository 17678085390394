import { connect } from 'react-redux';

import TableRow from './component';
import { getFormRequest, deleteFormRequest, updateForm } from '@/actions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
  getFormRequest,
  deleteFormRequest,
  updateForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableRow);
