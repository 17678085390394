import styled from "styled-components"

export const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
`

export const Column = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
`

export const Grid = styled.div`
    display: flex;
`