import {
  AUTHENTICATION_REQUEST,
  AUTHENTICATION_RESPONSE,
  AUTHENTICATION_RESPONSE_FAIL,
  AUTHENTICATION_SAVE_REDIRECT_URL,
} from '@/actions';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  name: null,
  displayableId: null,
  userIdentifier: null,
  error: null,
  redirect_url: '/',
  redirectFromLanding: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_REQUEST: {
      return { ...state, isAuthenticating: true };
    }
    case AUTHENTICATION_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        isAuthenticating: false,
        isAuthenticated: true,
        error: null,
      };
    }
    case AUTHENTICATION_RESPONSE_FAIL: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        error: action.payload.error,
      };
    }
    case AUTHENTICATION_SAVE_REDIRECT_URL: {
      return {
        ...state,
        redirect_url: action.payload.values.pathname,
        redirectFromLanding: false,
      };
    }
    default:
      return state;
  }
}
