import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, spacing, Modal, ContentText, colors
} from 'elemental-ui';
import { SubmissionStates } from '@/constants'
import {Button} from "@/components/blocks/Button"

class FormActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  handleOpen = () => this.setState({ opened: true });

  handleClose = (e) => {
    e.preventDefault()
    this.setState({ opened: false });
  }

render() {
  const { isSubmitting, isValid, errorText, submissionState } = this.props;
  const formSubmissionHasResult = submissionState === SubmissionStates.SUBMITTED 
  || submissionState === SubmissionStates.SUBMISSION_FAILED;
  const { opened } = this.state;
  return (
    
    <Box
      marginBottom={spacing.s}
      marginTop={spacing.s}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      
      <Button
        status="primary"
        type="submit"
        disabled={isSubmitting || !isValid}
        marginRight={spacing.xxs}
        onClick={this.handleOpen}
      >
        Finish
      </Button>

      <Modal opened={opened && formSubmissionHasResult} onClose={this.handleClose}>
        <ContentText color={errorText && colors.punchRed} mb={spacing.xxs}>
          {errorText || 'Form is submitted.'}
        </ContentText>
        <Box mt={spacing.xxl}>
          <Button onClick={this.handleClose}>Ok</Button>
        </Box>
      </Modal>
    </Box>
  );
}
}

FormActions.propTypes = {
  errorText: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  submissionState: PropTypes.number.isRequired,
};
FormActions.defaultProps = {
  errorText: ''
};
export default FormActions;
