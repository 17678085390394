import styled from 'styled-components'
import { Row } from '../Grid'

export const LogoContainer = styled(Row)`
    padding-left: 2em;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`

export const LogoIcon = styled.img`
    width: 73px;
    height: 19px;
`