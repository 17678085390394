import { put, takeLatest } from 'redux-saga/effects';

import {
  GET_PEOPLE_REQUEST,
  getPeopleResponse,
  getPeopleResponseFail,
} from '@/actions';

const MOCK = {
  categories: [
    {
      label: 'Organizations',
      id: 'organizations',
    },
    {
      label: 'All Users',
      id: 'allUsers',
    },
    {
      label: 'Active Directory',
      id: 'activeDirectory',
    },
  ],
  users: [
    {
      id: 1,
      displayName: 'Admin',
      categories: ['organizations', 'allUsers', 'activeDirectory'],
      emailAddress: 'admin@example.com',
      title: 'Administrator',
      department: 'N/A',
      presence: 'N/A',
      location: 'Minsk, Belarus',
      workPhone: '+375000000000',
    },
    {
      id: 2,
      displayName: 'Maksim',
      categories: ['allUsers', 'activeDirectory'],
      emailAddress: 'maksim@example.com',
      title: 'Sowtware Engineer',
      department: 'N/A',
      presence: 'N/A',
      location: 'Minsk, Belarus',
      workPhone: '+375444444444',
    },
    {
      id: 3,
      displayName: 'Alex',
      categories: ['allUsers', 'organizations'],
      emailAddress: 'alex@example.com',
      title: 'Sowtware Engineer',
      department: 'N/A',
      presence: 'N/A',
      location: 'Minsk, Belarus',
      workPhone: '+375292992929',
    },
  ],
};

function* submitStubForm() {
  try {
    yield put(getPeopleResponse(MOCK));
  } catch (error) {
    yield put(getPeopleResponseFail(error));
  }
}

export default function* () {
  yield takeLatest(GET_PEOPLE_REQUEST, submitStubForm);
}
