import { compose } from 'redux';
import { connect } from 'react-redux';

import SortableItem from './component';

const mapStateToProps = state => ({
  formikValues: state.form.formikValues,
});
const mapDispatchToProps = null;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SortableItem);
