import { fork } from 'redux-saga/effects';

import selectPeople from './selectPeople';
import authentication from './authentication';
import submitForm from './submitForm';
import saveFormSaga from './saveFormSaga';
import cancelSubmitForm from './cancelSubmitForm';
import getFormsList from './getFormsList';
import getForm from './getForm';
import updateForm from './updateForm';
import deleteForm from './deleteForm';
import getUser from './getUser';
import getUserList from './getUserList';
import createForm from './createForm';
import checkUserGorups from './checkUsersGroups';
import redirect from './redirect';

export default function* () {
  yield fork(selectPeople);
  yield fork(authentication);
  yield fork(submitForm);
  yield fork(saveFormSaga);
  yield fork(cancelSubmitForm);
  yield fork(getFormsList);
  yield fork(getForm);
  yield fork(updateForm);
  yield fork(deleteForm);
  yield fork(getUser);
  yield fork(getUserList);
  yield fork(createForm);
  yield fork(checkUserGorups);
  yield fork(redirect);
}
