import {
  NAME_VALIDATOR,
  USER_ID_VALIDATOR,
  LOCATION_VALIDATOR,
  SIX_DIGITS_VALIADATOR,
  EMAIL_VALIADATOR,
} from '@/constants';

export const validateName = (value = '') => /^[A-Z]?[a-z]{1,15}$/.exec(value);

export const validateEmployeeId = (value = '') => /^[a-z0-9]/i.exec(value);

export const validateLocation = (value = '') => /.{3,}/.exec(value);

export const sixDigits = (value = '') => /\d{6}/.exec(value);

export const email = (value = '') => /.+@.+\..+/i.exec(value);

export const getValidator = (name) => {
  switch (name) {
    case NAME_VALIDATOR:
      return validateName;

    case USER_ID_VALIDATOR:
      return validateEmployeeId;

    case LOCATION_VALIDATOR:
      return validateLocation;

    case SIX_DIGITS_VALIADATOR:
      return sixDigits;

    case EMAIL_VALIADATOR:
      return email;

    default:
      return null;
  }
};
