import { FORTUM_FORMS_BACKEND_ENDPOINT } from '@/constants';

export default (id, formConfiguration) => fetch(`${FORTUM_FORMS_BACKEND_ENDPOINT}/api/forms/${id}`, {
  headers: {
    'Access-Control-Allow-Origin': FORTUM_FORMS_BACKEND_ENDPOINT,
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  body: JSON.stringify({
    formConfiguration,
    href: formConfiguration.title.toLowerCase().split(' ').join('_'),
  }),
}).then(res => console.log(res));
