import React from 'react'
import PropTypes from 'prop-types'
import { TextAreaInput, BaseInputContainer, Label } from './styles'
import { Text } from "@/components/blocks/Text"
import { Row } from '../Grid';

export function TextAreaField(props) {
    const { label, required, error, errorMessage } = props;
    return (
        <div>
            <BaseInputContainer>
                <TextAreaInput as="textarea" {...props} placeholder="Type here.." />
            </BaseInputContainer>
            <Row>
                {error && <Text variant="warning">{errorMessage}</Text>}
            </Row>
        </div>
    )
}

TextAreaField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
}

TextAreaField.defaults = {
    label: "Placeholder",
    required: false
}
