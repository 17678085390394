import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DELETE_FORM_REQUEST,
  deleteFormResponse,
  deleteFormResponseFail,
  getFormsListRequest,
} from '@/actions';

import deleteForm from '@/api/deleteForm';

function* deleteFormRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(deleteForm, id);
    yield put(deleteFormResponse(response));
    yield put(getFormsListRequest());
    return true;
  } catch (error) {
    yield put(deleteFormResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(DELETE_FORM_REQUEST, deleteFormRequest);
}
