import { call, put, takeLatest } from 'redux-saga/effects';
import {
  UPDATE_FORM_REQUEST,
  updateFormResponse,
  updateFormResponseFail,
} from '@/actions';

import updateForm from '@/api/updateForm';

function* updateFormRequest({ payload }) {
  try {
    const { id, form } = payload;
    const response = yield call(updateForm, id, form);
    yield put(updateFormResponse(response));
    return true;
  } catch (error) {
    yield put(updateFormResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(UPDATE_FORM_REQUEST, updateFormRequest);
}
