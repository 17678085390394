import { takeLatest } from 'redux-saga/effects';

import { SAVE_FORM } from '@/actions';

function* saveForm({ payload }) {
  try {
    for (const key in payload.values) {
      sessionStorage.setItem(key, payload.values[key]);
    }
  } catch (error) {
    console.log('>e', error);
  }
  yield true;
}

export default function* () {
  yield takeLatest(SAVE_FORM, saveForm);
}
