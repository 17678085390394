export const LANDING_PAGE = '/';

export const WORKSPACES_PAGE = '/workspaces';
export const ADMIN_PANEL_PAGE = '/admin-panel';
export const ADMIN_PANEL_FORMS_LIST_PAGE = `${ADMIN_PANEL_PAGE}/forms-list`;
export const AUTHENTICATION_PAGE = '/authentication';
export const AUTHENTICATION_END_POINT_PAGE = '/authentication-redirect';
export const NOT_AUTHORIZED_PAGE = '/logout';
export const FORMS_FOR_ASR_TICKETS_PAGE = `${WORKSPACES_PAGE}/forms-for-ars-tickets`;

export const CLOUD_PLATFORM_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/cloud-platform-request-page`;
export const CONTTI_ACCESS_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/contti-access-request-page`;
export const ATOS_SERVER_ORDER_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/atos-server-order-page`;
export const CREATE_AD_ACCOUNT_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/create-ad-account-page`;
export const CLOUD_PLATFORM_ORDER_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/cloud-platform-order-page`;
export const EBOK_HEAT_POLAND_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/ebok-heat-poland-request-page`;
export const MOJE_FORTUM_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/moje-fortum-request-page`;
export const NEW_BRANCH_DATA_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/new-branch-data`;
export const HDEF_POLAND_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/hdef-poland-request`;
export const NEW_SOFTWARE_PUBLICATION_PAGE =
`${FORMS_FOR_ASR_TICKETS_PAGE}/new-software-publication`;
export const NEW_SUPPLIER_DATA_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/new-supplier-data`;
export const PAWE_NEW_EMPLOYEES_PAGE =
`${FORMS_FOR_ASR_TICKETS_PAGE}/pawe-new-employees`;
export const REQUEST_FOR_DEPLOYMENT_OPERATION_PHASE_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/request-for-deployment-operation-phase`;
export const PROJECT_WISE_ACCESS_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/projectWise-access-request-application`;
export const REQUEST_FOR_LA_CODE_FOR_APPLICATION_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/request-for-la-code-for-application`;
export const REQUEST_FOR_LA_LIFECYCLE_TO_TERMINATED_PAGE =
`${FORMS_FOR_ASR_TICKETS_PAGE}/request-for-la-lifecycle-to-terminated`;
export const REQUEST_FOR_LA_CODE_FOR_CASE_OR_PROJECT_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/request-for-la-code-for-case-or-project`;
export const RPA_RUN_BY_REQUEST_PAGE =
`${FORMS_FOR_ASR_TICKETS_PAGE}/rpa-run-by-request`;
export const SUPPLIER_QUALIFICATION_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/supplier-qualification-request`;
export const STRUCTURED_PRODUCTS_ASIAN_OPTION_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/structured-products-asian-option`;
export const STRUCTURED_PRODUCTS_EUROPEAN_OPTION_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/structured-products-european-option`;
export const STRUCTURED_PRODUCTS_PEAK_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/structured-products-peak`;
export const VISUAL_ARCHIVE_FINLAND_ACCESS_REQUEST_PAGE =
  `${FORMS_FOR_ASR_TICKETS_PAGE}/visual-archive-finland-access-request`;

export const PAGE_NAMES = {
  /* Root pages */
  [LANDING_PAGE]: 'Home',
  [WORKSPACES_PAGE]: 'Workspaces',

  /* Workspace pages */
  [FORMS_FOR_ASR_TICKETS_PAGE]: 'Forms for ARS Tickets',
  [ADMIN_PANEL_PAGE]: 'Admin Panel',
  [ADMIN_PANEL_PAGE]: 'Admin Panel',
  [ADMIN_PANEL_FORMS_LIST_PAGE]: 'Admin Panel Forms List',

  /* Workspace -> ARS Ticket forms */
  [CLOUD_PLATFORM_REQUEST_PAGE]: 'Cloud Platform Request',
  [CONTTI_ACCESS_REQUEST_PAGE]: 'Contti Access Request',
  [CREATE_AD_ACCOUNT_PAGE]: 'Create AD Account',
  [CLOUD_PLATFORM_ORDER_PAGE]: 'Cloud Platform Order',
  [EBOK_HEAT_POLAND_REQUEST_PAGE]: 'eBOK Heat Poland Request',
  [HDEF_POLAND_REQUEST_PAGE]: 'HDEF Poland Request',
  [MOJE_FORTUM_REQUEST_PAGE]: 'Moje Fortum Request',
  [NEW_BRANCH_DATA_PAGE]: 'New Branch Data',
  [NEW_SOFTWARE_PUBLICATION_PAGE]: 'New Software Publication',
  [NEW_SUPPLIER_DATA_PAGE]: 'New Supplier Data',
  [PAWE_NEW_EMPLOYEES_PAGE]: 'PAWE - New Employees',
  [REQUEST_FOR_DEPLOYMENT_OPERATION_PHASE_PAGE]: 'Request for Deployment/Operation Page',
  [PROJECT_WISE_ACCESS_REQUEST_PAGE]: 'Project Wise Access Request',
  [REQUEST_FOR_LA_CODE_FOR_CASE_OR_PROJECT_PAGE]: 'Request for LA Code for Case or Project',
  [REQUEST_FOR_LA_CODE_FOR_APPLICATION_PAGE]: 'Request for LA Code for Application',
  [REQUEST_FOR_LA_LIFECYCLE_TO_TERMINATED_PAGE]: 'Request for LA Lifecycle to Terminated',
  [RPA_RUN_BY_REQUEST_PAGE]: 'RPA Run-By-Request',
  [SUPPLIER_QUALIFICATION_REQUEST_PAGE]: 'Supplier Qualification Request',
  [ATOS_SERVER_ORDER_PAGE]: 'ATOS Server Order',
  [STRUCTURED_PRODUCTS_EUROPEAN_OPTION_PAGE]: 'Structured Products - European Option',
  [STRUCTURED_PRODUCTS_PEAK_PAGE]: 'Structured Products - Peak',
  [STRUCTURED_PRODUCTS_ASIAN_OPTION_PAGE]: 'Structured Products - Asian Option',
  [VISUAL_ARCHIVE_FINLAND_ACCESS_REQUEST_PAGE]: 'Visual Archive Finland Access Request',
};
