import {
  CHECKGROUPS_ENDPOINT,
} from '@/constants';

import { getAzureApiToken } from '@/helpers/azure/apiToken';

export const checkUserGroups = groups => getAzureApiToken()
  .then(token => fetch(`${CHECKGROUPS_ENDPOINT}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
    body: JSON.stringify(groups),
  }).then(res => res.json()));
