import actionCreator from '@/helpers/redux/actionCreator';

export const GET_PEOPLE_REQUEST = '@/GET_PEOPLE_REQUEST';
export const GET_PEOPLE_RESPONSE = '@/GET_PEOPLE_RESPONSE';
export const GET_PEOPLE_RESPONSE_FAIL = '@/GET_PEOPLE_RESPONSE_FAIL';

export const getPeopleRequest = fields => actionCreator(
  GET_PEOPLE_REQUEST,
  fields,
);

export const getPeopleResponse = entitities => actionCreator(
  GET_PEOPLE_RESPONSE,
  entitities,
);

export const getPeopleResponseFail = error => actionCreator(
  GET_PEOPLE_RESPONSE_FAIL,
  error,
);
