import { connect } from 'react-redux';

import FormActions from './component';

const mapStateToProps = state => ({
  isSubmitting: state.form.isSubmitting,
  errorText: state.form.error,
  submissionState: state.form.submissionState
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormActions);
