import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_FORM_REQUEST,
  createFormResponse,
  createFormResponseFail,
  updateBeforeCreate,
} from '@/actions';

import saveForm from '@/api/saveForm';

function* updateFormRequest({ payload }) {
  try {
    const { document_id, formConfiguration } = payload;
    const href = formConfiguration.title.toLowerCase().split(' ').join('_');
    const form = {
      formConfiguration,
      document_id,
      href,
    };
    const response = yield call(saveForm, form);
    yield put(createFormResponse(response));
    yield put(updateBeforeCreate(document_id));
    return true;
  } catch (error) {
    yield put(createFormResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(CREATE_FORM_REQUEST, updateFormRequest);
}
