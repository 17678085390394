import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {  CheckBoxContainer, CheckboxLabel, CheckBoxInput } from './styles'
import { Field } from 'formik'

export function CheckField(props) {
    const [checked, setChecked] = useState(props.checked)
    const { label, required } = props;

    const flip = (e) => {
        e.preventDefault();
        setChecked(!checked)
    }

    return (
        <Field
            type="checkbox"
            {...props}
            render={({ field, form: { isSubmitting } }) => (
                <CheckBoxContainer>
                    <CheckBoxInput type="checkbox" {...field} />
                    <CheckboxLabel>
                        {label}
                    </CheckboxLabel>
                </CheckBoxContainer>

            )} />
    )
}

CheckField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool
}

CheckField.defaults = {
    label: "Placeholder",
    required: false
}
