import { compose } from 'redux';
import { connect } from 'react-redux';

import NotAuthorizedPage from './component';
import { authenticationRequest } from '@/actions';

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  redirect_url: state.user.redirect_url,
  error: state.user.error,
});
const mapDispatchToProps = ({
  authenticationRequest,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(NotAuthorizedPage);
