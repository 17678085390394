import actionCreator from '@/helpers/redux/actionCreator';

export const GET_USERLIST_REQUEST = '@/GET_USERLIST_REQUEST';
export const GET_USERLIST_RESPONSE = '@/GET_USERLIST_RESPONSE';
export const GET_USERLIST_RESPONSE_FAIL = '@/GET_USERLIST_RESPONSE_FAIL';

export const getUserListRequest = () => actionCreator(GET_USERLIST_REQUEST);

export const getUserListResponse = user => actionCreator(GET_USERLIST_RESPONSE, {
  user,
});

export const getUserListResponseFail = error => actionCreator(GET_USERLIST_RESPONSE_FAIL, {
  error,
});
