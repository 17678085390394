import React from 'react';
import PropTypes from 'prop-types';

import Page from '@/components/blocks/Page';
import DynamicForm from '@/components/forms/DynamicForm';
import { Grid, Heading } from 'elemental-ui';
import { MAX_WIDTH } from '@/constants';
import BaseLayout from '@/components/layouts/Base';

const DynamicFormPage = ({ form }) => (
    <BaseLayout>
        <Page>
            <Grid style={MAX_WIDTH}>
                <Heading mb="30px" weight="700" color="#333333" style={{fontFamily: "inherit", fontSize: "31px"}}>
                    {form.formConfiguration.title}
                </Heading>
                <DynamicForm />
            </Grid>
        </Page>
    </BaseLayout>
);

DynamicFormPage.propTypes = {
    form: PropTypes.object.isRequired,
};

DynamicFormPage.defaultProps = {};

export default DynamicFormPage;
