import {
  CREATE_FORM_REQUEST,
  CREATE_FORM_RESPONSE,
  CREAETE_RESPONSE_FAIL,
} from '@/actions';

const initialState = {
  formIsUpdated: false,
  isRequested: false,
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_FORM_REQUEST:
      return {
        ...state,
        isRequested: true,
      };
    case CREATE_FORM_RESPONSE:
      return {
        ...state,
        isRequested: false,
      };
    case CREAETE_RESPONSE_FAIL:
      return {
        ...state,
        isRequested: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
