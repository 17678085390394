import React from 'react';
import PropTypes from 'prop-types';

import Page from '@/components/blocks/Page';
import { Grid, Heading } from 'elemental-ui';
import { MAX_WIDTH } from '@/constants';
import Table from '@/components/blocks/Table';
import AddFormButton from '@/components/blocks/AddFormButton';
import AccessDenied from '@/components/blocks/AccessDenied';
import BaseLayout from '@/components/layouts/Base';

class AdminPanelFormsListPage extends React.PureComponent {
  componentDidMount() {
    const { getFormsListRequest } = this.props;
    getFormsListRequest();
  }

  render() {
    const { userIsAdmin, groupsIsRequested } = this.props;
    return (
      <BaseLayout>
        <Page>
          <Grid style={MAX_WIDTH}>
            {groupsIsRequested && userIsAdmin ? (
              <>
                <Heading level={5}>Forms List</Heading>
                <Table />
                <AddFormButton />
              </>
            ) : null }
          </Grid>
          {
              groupsIsRequested && !userIsAdmin ? (
                <AccessDenied />
              ) :
                null
            }
        </Page>
      </BaseLayout>
    );
  }
}

AdminPanelFormsListPage.propTypes = {
  getFormsListRequest: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  groupsIsRequested: PropTypes.bool.isRequired,
};
AdminPanelFormsListPage.defaultProps = {};

export default AdminPanelFormsListPage;
