import { compose } from 'redux';
import { connect } from 'react-redux';

import { getPeopleRequest } from '@/actions';

import SelectPeople from './component';

const mapStateToProps = (state) => ({
  selectPeople: state.selectPeople,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPeopleRequest: async () => dispatch(getPeopleRequest()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SelectPeople);
