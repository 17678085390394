import React from 'react';
import PropTypes from 'prop-types';
import { ContentText, spacing, colors } from 'elemental-ui';

const ErrorMessage = ({ errorText }) => (
  <ContentText
    marginBottom={spacing.m}
    color={colors.punchRed}
    style={{
      textAlign: 'center',
    }}
  >
    {errorText}
  </ContentText>
);

ErrorMessage.propTypes = {
  errorText: PropTypes.string,
};

ErrorMessage.defaultProps = {
  errorText: '',
};

export default ErrorMessage;
