// import { getAzureApiToken } from '@/helpers/azure/apiToken';
import { extractFormikFields, extractFieldLabels } from '@/helpers/azure/forms';
import { ARS_CREATE_TICKET_ENDPOINT, FORTUM_ENDPOINT } from '@/constants';

// const corsProxy = 'http://0.0.0.0:8080';
// const corsPrefix = url => `${corsProxy}/${url}`;

/**
 * Returns headers object ready to work with cross domain resource and with all keys
 */
const buildAzureHeaders = async () => {
  const headers = new Headers();

  try {
    // const token = await getAzureApiToken();
    // const bearer = `Bearer ${token}`;

    // headers.set('Authorization', bearer);

    // @todo take care about HTTPS and allowed '*' origin!
    headers.set('Access-Control-Allow-Origin', FORTUM_ENDPOINT);
    headers.set('Access-Control-Allow-Methods', 'GET, PUT, POST, DELETE, OPTIONS');
    headers.set('Content-Type', 'application/json');
    headers.set('apikey', process.env.REACT_APP_FORTUM_APIKEY);
    headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_FORTUM_SUBSCRIPTION_KEY);

    return headers;
  } catch (error) {
    throw error;
  }
};

/**
 * @param {array} fieldsArray - Array of mapped fields (mapping implemented in extractFormikFields)
 * @param {string} fieldsArray[n].label - Human-radable field name
 * @param {string} fieldsArray[n].value - Field value
 */
export const compileFormFields = (fieldsArray, ignoreNullFields = false) => fieldsArray
  .reduce((acc, item) => acc = acc.concat(`${item.label || item.name}:\r\n${item.value}\r\n\r\n`), '');

export const sendForm = async (formData, configuration, userId) => {
  // Extract fields array (field label and value must be connected)

  const { fileUpload, fileUploadSize, ...fieldData } = formData;
  const fieldLabels = extractFieldLabels(configuration);
  const fieldsArray = extractFormikFields(fieldData, fieldLabels, true);
  // Compile all fields in one long text
  const description = compileFormFields(fieldsArray);

  // Get CORS header with authentication
  const headers = await buildAzureHeaders();
  const fileUploadData = fileUpload
    ? {
      A1_attachmentName: fileUpload.split(';')[0],
      A1_attachmentData: fileUpload.split('base64,')[1],
      A1_attachmentOrigSize: 0,
    }
    : {};
  // Request options
  const options = {
    headers,
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      ContentDescription: description,
      ContentUserName: userId,
      ContentInterfaceID: configuration.contentInterfaceID,
      ContentTitle: configuration.title,
      ...fileUploadData,
    }),
  };
  return await fetch(ARS_CREATE_TICKET_ENDPOINT, options);
};
