import Head from '@/components/blocks/Header';
import { PageWrapper } from './styles';
import React from 'react';

const BaseLayout = ({ children }) => (
  <PageWrapper>
    <Head />
    {children}
  </PageWrapper>
);

export default BaseLayout;
