import React from 'react';
import PropTypes from 'prop-types';
import Page from '@/components/blocks/Page';
import { LinkButton } from './styles';
import BaseLayout from '@/components/layouts/Base';

class LandingPage extends React.PureComponent {
  componentDidMount() {
    const { getFormsListRequest, redirectAction } = this.props;
    getFormsListRequest();
    const loginRequest = sessionStorage.getItem('msal.login.request');
    if (loginRequest) redirectAction(loginRequest.split('redirect=')[1]);
    sessionStorage.removeItem('msal.login.request');
  }

  render() {
    const { heading, formsList, getFormRequest } = this.props;
    return (
      <BaseLayout>
      <Page>
          <h1>{heading || 'Landing Page'}</h1>
          <ul>
            {formsList.map((link, idx) => (
              <li key={idx}>
                <LinkButton type="button" onClick={() => getFormRequest('id', link.document_id, link.href)}>{link.label}</LinkButton>
              </li>
            ))}
          </ul>
      </Page>
      </BaseLayout> 
    );
  }
}


LandingPage.propTypes = {
  heading: PropTypes.string,
  getFormsListRequest: PropTypes.func.isRequired,
  formsList: PropTypes.array.isRequired,
  getFormRequest: PropTypes.func.isRequired,
  redirectAction: PropTypes.func.isRequired,
};
LandingPage.defaultProps = {
  heading: '',
};

export default LandingPage;
