import React from 'react';
import { Provider } from 'react-redux';
import store, { history } from '@/store';
import Router from '@/router';
import promise from 'es6-promise';
import 'isomorphic-fetch';
import { ThemeProvider } from 'styled-components';
import Theme from "@/theme/theme"

promise.polyfill();

class App extends React.Component {
  constructor(props) {
    super(props);
    console.log(`Running version: ${process.env.VERSION} (${process.env.NODE_ENV})`);
  }
  render() {
    return (
      
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <Router history={history}/>
        </ThemeProvider>
      </Provider>
     
    );
  }
}

export default App;
