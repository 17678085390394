import { connect } from 'react-redux';

import SaveFormAction from './component';

const mapStateToProps = null;

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveFormAction);
