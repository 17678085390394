import React from 'react';
import PropTypes from 'prop-types';

import AdminPanelElementsList from '@/components/blocks/AdminPanelElementsList';
import AdminPanelDynamicForm from '@/components/forms/AdminPanelDynamicForm';
import Notification from '@/components/blocks/Notification';
import AccessDenied from '@/components/blocks/AccessDenied';

class AdminPanelPage extends React.PureComponent {
  onContextMenuHandle = (event) => {
    event.preventDefault();
  };

  render() {
    const { notificationOpened, userIsAdmin, groupsIsRequested } = this.props;
    return (
      <>
        {userIsAdmin ? (
          <div onContextMenu={event => this.onContextMenuHandle(event)}>
            <AdminPanelElementsList />
            <AdminPanelDynamicForm />
            {notificationOpened ? <Notification /> : null}
          </div>
        ) : null}
        {groupsIsRequested && !userIsAdmin ? <AccessDenied /> : null}
      </>
    );
  }
}

AdminPanelPage.propTypes = {
  notificationOpened: PropTypes.bool.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  groupsIsRequested: PropTypes.bool.isRequired,
};
AdminPanelPage.defaultProps = {};

export default AdminPanelPage;
