import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from './styles'
import { Text } from "@/components/blocks/Text"
import { Row } from '../Grid'
import { Field } from "formik"
import { mapSelectItems } from '@/helpers/form/inputHelpers'

export function SelectField(props) {
    const { items, error, errorMessage, name, selected, disabled } = props

    //Select expects label key instead of name
    const {selectedItem, mappedItems} = mapSelectItems(items, selected);

    return (
        <div>
            <Field render={({form: {values } }) => (
                    <SelectInput {...props} 
                        classNamePrefix="select"
                        selectedItem={selectedItem || null}
                        defaultValue={selectedItem || null}
                        // onChange callback does not trigger value to change so we need to pass it manually.
                        onChange={(v) => values[name] = v.value}
                        isDisabled={disabled} 
                        options={mappedItems} 
                        isSearchable={false}
                    />
            )} />
            <Row>
                {error && <Text variant="warning">{errorMessage}</Text>}
            </Row>
        </div>
    )
}

SelectField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, name: PropTypes.string })),
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    selected: PropTypes.string
}
