import styled from 'styled-components';

const AddButton = styled.a`
  border-radius: 50%;
  background: ${props => props.theme.main};
  color: white;
  border: 0;
  width: 62px;
  height: 62px;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  position: fixed;
  bottom: 10%;
  left: 80%;
  z-index: 228;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export default AddButton;
