import React from 'react';

import Page from '@/components/blocks/Page';

class AuthenticationEndPointPage extends React.PureComponent {
  render() {
    return (
      <Page />
    );
  }
}

AuthenticationEndPointPage.propTypes = {
};
AuthenticationEndPointPage.defaultProps = {};

export default AuthenticationEndPointPage;
