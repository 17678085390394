import { compose } from 'redux';
import { connect } from 'react-redux';

import AdminPanelFormsListPage from './component';
import {
  getFormsListRequest,
  getFormRequest,
} from '@/actions';

const mapStateToProps = state => ({
  userIsAdmin: state.checkUserGroups.userIsAdmin,
  groupsIsRequested: state.checkUserGroups.isRequested,
});

const mapDispatchToProps = ({
  getFormsListRequest,
  getFormRequest,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AdminPanelFormsListPage);
