import { compose } from 'redux';
import { connect } from 'react-redux';

import AdminPanelPage from './component';
import {
  authenticationRequest, editFieldOpen, setFormikParameters, saveFormikValues,
} from '@/actions';

const mapStateToProps = state => ({
  notificationOpened: state.addedEntityNotification.notificationOpened,
  userIsAdmin: state.checkUserGroups.userIsAdmin,
  groupsIsRequested: state.checkUserGroups.isRequested,
});
const mapDispatchToProps = ({
  authenticationRequest,
  editFieldOpen,
  setFormikParameters,
  saveFormikValues,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AdminPanelPage);
