import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  AUTHENTICATION_REQUEST,
  authenticationResponse,
  authenticationResponseFail,
} from '@/actions';

import { AZURE_AUTHENTICATION_SCOPES, AUTHENTICATION_PAGE } from '@/constants';

import { userAgentApplication } from '@/helpers/azure/authentication';

function* authenticate({ payload }) {
  try {
    yield new Promise((resolve, reject) => {
      userAgentApplication
        .loginRedirect(
          AZURE_AUTHENTICATION_SCOPES,
          'domain_hint=fortum.com',
          payload.values.postLogOutLogin ? '' : 'prompt=none',
        )
        .then(token => resolve(token))
        .catch(error => reject(error));
    });
    const {
      name,
      displayableId,
      userIdentifier,
    } = userAgentApplication.getUser();
    yield put(authenticationResponse(name, displayableId, userIdentifier));
    yield put(push(payload.values.redirect_url));
  } catch (error) {
    yield put(authenticationResponseFail(error));
    yield put(push(`${AUTHENTICATION_PAGE}?redirect=${payload.values.redirect_url}`));
  }
}

export default function* () {
  yield takeLatest(AUTHENTICATION_REQUEST, authenticate);
}
