
/**
 * Validates list of checkboxes from Formik Values.
 * @param {FormikValues} values Formik Values object or key-value object where key is a field name
 * and value is a field value.
 * @param {string} fieldName - field name prefix.
 * @param {{ label: string, value: string }} options Label-Value objects list.
 */
export const checkboxGroupMandatorySelection = (values, fieldName, options) => {
  if (!values || Object.keys(values).length === 0) {
    return false;
  }

  if (!options || !Array.isArray(options)) {
    throw new TypeError('Options is not array');
  }

  const targetFieldNames = options.map(options => `${fieldName}-----${options.value}`);

  return targetFieldNames.some(targetFieldName => !!values[targetFieldName]);
};
