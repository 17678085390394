import actionCreator from '@/helpers/redux/actionCreator';

export const GET_USER_REQUEST = '@/GET_USER_REQUEST';
export const GET_USER_RESPONSE = '@/GET_USER_RESPONSE';
export const GET_USER_RESPONSE_FAIL = '@/GET_USER_RESPONSE_FAIL';

export const getUserRequest = id => actionCreator(GET_USER_REQUEST, { id });

export const getUserResponse = user => actionCreator(GET_USER_RESPONSE, {
  user,
});

export const getUserResponseFail = error => actionCreator(GET_USER_RESPONSE_FAIL, {
  error,
});
