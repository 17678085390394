const Theme = {
    neutral: '#f2f2f2',
    neutraltext: '#616161',
    background: '#ffffff',
    main: '#377A6C',
    disabled: '#e8e8eb',
    input: {
        background: "#F5F5F5",
        contrast: "#e8e8e8"
    },
    text: {
        default: '#000000',
        soft: '#333333',
        neutral: '#616161',
        warning: '#b83e4d'
    },
    button: {
        default: '#377A6C',
        warning: '#b83e4d'
    }
};

export default Theme;
