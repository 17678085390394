import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { REDIRECT_ACTION } from '@/actions';

function* redirect({ payload }) {
  try {
    const { redirectUrl } = payload;
    yield put(push(redirectUrl));
  } catch (error) {
    console.log('>e', error);
  }
}

export default function* () {
  yield takeLatest(REDIRECT_ACTION, redirect);
}
