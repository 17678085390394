import {
  GET_PEOPLE_REQUEST,
  GET_PEOPLE_RESPONSE,
  GET_PEOPLE_RESPONSE_FAIL,
} from '@/actions';

import createBasicReducer from '@/helpers/redux/createBasicReducer';

export default function (state, action) {
  return createBasicReducer({
    requestActionType: GET_PEOPLE_REQUEST,
    responseActionType: GET_PEOPLE_RESPONSE,
    failureResponseActionType: GET_PEOPLE_RESPONSE_FAIL,
  })(state, action);
}
