import {
  SET_TAG,
  SET_TAGS,
  REMOVE_TAG,
} from '@/actions';

const initialState = {
  tags: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TAG:
      return {
        ...state,
        tags: [].concat(state.tags, action.payload.tag),
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case REMOVE_TAG: {
      const newTags = [].concat(state.tags);
      newTags.splice(action.payload.id, 1);
      return {
        ...state,
        tags: newTags,
      };
    }
    default:
      return state;
  }
}
