import styled from 'styled-components';
import { Modal } from 'elemental-ui';

export const ModalStyled = styled(Modal)`
  width: 846px;
  height: 754px;
  & > div {
    display: flex;
    justify-content: center;
  }
`;

export const InputTag = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  font-family: GT-Eesti-Pro-Display, arial, sans-serif;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border-color: #bebec2;
  border-style: solid;
  border-width: 0.125rem;
  box-shadow: none;
  color: #41414a;
  font-size: 1.125rem;
  height: 4rem;
  padding-left: 0.875rem;
  padding-right: 3.875rem;
  -webkit-transition: background 250ms, border-color 300ms;
  transition: background 250ms, border-color 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  width: 100%;
  padding: 5px 5px 0;
`;

export const InputTagInput = styled.input`
  &:focus {
    outline: none;
  }
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  font-family: GT-Eesti-Pro-Display, arial, sans-serif;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border-color: #bebec2;
  border-style: solid;
  border-width: 0.125rem;
  box-shadow: none;
  color: #41414a;
  font-size: 1.125rem;
  height: 4rem;
  padding-left: 0.875rem;
  padding-right: 3.875rem;
  -webkit-transition: background 250ms, border-color 300ms;
  transition: background 250ms, border-color 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  width: 100%;
  padding: 5px 5px 0;
`;

export const InputTagTags = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  background: none;
  flex-grow: 1;
  padding: 0;
  align-items: center;
`;

export const InputTagTagsLi = styled.li`
  align-items: center;
  background: #5ac37d;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  height: 50%;
`;

export const InputTagTagsLiButton = styled.button`
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  transform: rotate(45deg);
  width: 15px;
`;

export const InputTagTagsLiInputTagTagsInput = styled.li`
  align-items: center;
  background: #85a3bf;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  background: none;
  flex-grow: 1;
  padding: 0;
  list-style: none;
`;
