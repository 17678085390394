import React from 'react'
import { IconButtonElement, ButtonElement, ButtonLabel } from './styles'
import * as Icons from "../Icons"

export function IconButton(props) {
    const Icon = Icons[props.icon];
    return (
        <IconButtonElement {...props}>
            <Icon />
        </IconButtonElement>
    )
}

export function Button(props) {
    return (
        <ButtonElement {...props}>
            <ButtonLabel {...props}>{props.children}</ButtonLabel>
        </ButtonElement>
    )
}