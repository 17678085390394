import {
  ADMIN_PANEL_CUSTOM_FORM,
  CONTTI_ACCESS_REQUEST_FORM,
  CREATE_AD_ACCOUNT_FORM,
  HDEF_POLAND_REQUEST_FORM,
  MOJE_FORTUM_REQUEST_FORM,
  NEW_BRANCH_DATA_FORM,
  NEWS_SOFTWARE_PUBLICATION_FORM,
  NEW_SUPPLIER_DATA_FORM,
  PAWE_NEW_EMPLOYEES_FORM,
  PROJECT_WISE_ACCESS_REQUEST_FORM,
  REQUEST_FOR_DEPLOYMENT_OPERATION_PHASE_FORM,
  REQUEST_FOR_LA_CODE_FOR_APPLICATION_FORM,
  REQUEST_FOR_LA_CODE_FOR_CASE_OR_PROJECT_FORM,
  REQUEST_FOR_LA_LIFECYCLE_TO_TERMINATED_FORM,
  RPA_RUN_BY_REQUEST_FORM,
  SUPPLIER_QUALIFICATION_REQUEST_FORM,
  CLOUD_PLATFORM_REQUEST_FORM,
  CLOUD_PLATFORM_ORDER_FORM,
  ATOS_SERVER_ORDER_FORM,
  EBOK_HEAT_POLAND_REQUEST_FORM,
  STRUCTURED_PRODUCTS_EUROPEAN_OPTION_FORM,
  STRUCTURED_PRODUCTS_PEAK_FORM,
  STRUCTURED_PRODUCTS_ASIAN_OPTION_FORM,
  VISUAL_ARCHIVE_FINLAND_ACCESS_REQUEST_FORM,
} from '@/constants';

// TODO: fill in correct interface id's for all forms
export default {
  [ADMIN_PANEL_CUSTOM_FORM]: 'API_LA001346',
  [CONTTI_ACCESS_REQUEST_FORM]: '',
  [ATOS_SERVER_ORDER_FORM]: '',
  [CREATE_AD_ACCOUNT_FORM]: 'API_LA001346',
  [CLOUD_PLATFORM_ORDER_FORM]: '',
  [EBOK_HEAT_POLAND_REQUEST_FORM]: '',
  [HDEF_POLAND_REQUEST_FORM]: '',
  [MOJE_FORTUM_REQUEST_FORM]: '',
  [NEW_BRANCH_DATA_FORM]: '',
  [NEWS_SOFTWARE_PUBLICATION_FORM]: '',
  [NEW_SUPPLIER_DATA_FORM]: '',
  [PAWE_NEW_EMPLOYEES_FORM]: 'API_LA001346',
  [REQUEST_FOR_DEPLOYMENT_OPERATION_PHASE_FORM]: '',
  [PROJECT_WISE_ACCESS_REQUEST_FORM]: '',
  [REQUEST_FOR_LA_CODE_FOR_APPLICATION_FORM]: '',
  [REQUEST_FOR_LA_CODE_FOR_CASE_OR_PROJECT_FORM]: '',
  [REQUEST_FOR_LA_LIFECYCLE_TO_TERMINATED_FORM]: '',
  [RPA_RUN_BY_REQUEST_FORM]: '',
  [SUPPLIER_QUALIFICATION_REQUEST_FORM]: 'API_LA001346',
  [CLOUD_PLATFORM_REQUEST_FORM]: '',
  [ATOS_SERVER_ORDER_FORM]: '',
  [STRUCTURED_PRODUCTS_ASIAN_OPTION_FORM]: '',
  [STRUCTURED_PRODUCTS_EUROPEAN_OPTION_FORM]: '',
  [STRUCTURED_PRODUCTS_PEAK_FORM]: '',
  [VISUAL_ARCHIVE_FINLAND_ACCESS_REQUEST_FORM]: '',
};
