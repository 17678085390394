export default [
  {
    name: 'None',
    value: 'none',
  },
  {
    name: 'Name',
    value: 'name',
  },
  {
    name: 'Email',
    value: 'email',
  },
  {
    name: 'User Id',
    value: 'userId',
  },
  {
    name: 'Location',
    value: 'location',
  },
  {
    name: 'Six Digits',
    value: 'sixDigits',
  },
];
