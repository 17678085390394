import {
  NOTIFICATION_CLOSE,
  NOTIFICATION_OPEN,
} from '@/actions';

const initialState = {
  notificationOpened: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_OPEN:
      return {
        ...state,
        notificationOpened: true,
      };
    case NOTIFICATION_CLOSE:
      return {
        ...state,
        notificationOpened: false,
      };
    default:
      return state;
  }
}
