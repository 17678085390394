export const VIEWS = [
  { name: 'List View', value: 'listView' },
  { name: 'Detailed View', value: 'detailedView' },
];

export const COLUMNS = [
  {
    id: 'organizations',
    data: [
      {
        name: 'Display Name',
        dataIndex: 'displayName',
      },
      {
        name: 'Email Address',
        dataIndex: 'emailAddress',
      },
      {
        name: 'Title',
        dataIndex: 'title',
      },
      {
        name: 'Department',
        dataIndex: 'department',
      },
      {
        name: 'Presence',
        dataIndex: 'presence',
      },
      {
        name: 'Location',
        dataIndex: 'location',
      },
    ],
  },
  {
    id: 'allUsers',
    data: [
      {
        name: 'Display Name',
        dataIndex: 'displayName',
      },
    ],
  },
  {
    id: 'activeDirectory',
    data: [
      {
        name: 'Display Name',
        dataIndex: 'displayName',
      },
      {
        name: 'Email Address',
        dataIndex: 'emailAddress',
      },
      {
        name: 'Title',
        dataIndex: 'title',
      },
      {
        name: 'Department',
        dataIndex: 'department',
      },
      {
        name: 'Presence',
        dataIndex: 'presence',
      },
      {
        name: 'Work Phone',
        dataIndex: 'workPhone',
      },
      {
        name: 'Location',
        dataIndex: 'location',
      },
    ],
  },
];
