/* Azure */
export const AZURE_ENDPOINT = 'https://graph.microsoft.com/v1.0';

export const AZURE_PROFILE_ENDPOINT = `${AZURE_ENDPOINT}/me`;
export const CHECKGROUPS_ENDPOINT = `${AZURE_PROFILE_ENDPOINT}/checkMemberGroups`;

/* Fortum */
export const FORTUM_ENDPOINT = 'https://fortumservices.azure-api.net';
export const FORTUM_FORMS_BACKEND_ENDPOINT = 'https://fortum-forms-backend.azurewebsites.net';

export const ARS_TICKETS_ENDPOINT = `${FORTUM_ENDPOINT}/arstickets`;
export const ARS_TICKET_IDS_ENDPOINT = `${ARS_TICKETS_ENDPOINT}/ticketids`;
export const ARS_CREATE_TICKET_ENDPOINT = `${ARS_TICKETS_ENDPOINT}/createticket`;
