export const ADMIN_PANEL_CUSTOM_FORM = 'adminPanelCustomForm';
export const ATOS_SERVER_ORDER_FORM = 'atosServerOrder';
export const CREATE_AD_ACCOUNT_FORM = 'Create AD Account';
export const HDEF_POLAND_REQUEST_FORM = 'HDEFPolandRequestForm';
export const MOJE_FORTUM_REQUEST_FORM = 'MojeFortumRequestForm';
export const NEW_BRANCH_DATA_FORM = 'newBranchDataForm';
export const NEWS_SOFTWARE_PUBLICATION_FORM = 'newSoftWarePublicationForm';
export const NEW_SUPPLIER_DATA_FORM = 'newSupplierDataForm';
export const PAWE_NEW_EMPLOYEES_FORM = 'PAWE - New Employees';
export const REQUEST_FOR_LA_LIFECYCLE_TO_TERMINATED_FORM = 'requestForLALifecycleToTErminatedForm';
export const REQUEST_FOR_DEPLOYMENT_OPERATION_PHASE_FORM = 'requestForDeploymentOpreationPhaseForm';
export const PROJECT_WISE_ACCESS_REQUEST_FORM = 'projectWiseAccessRequestForm';
export const REQUEST_FOR_LA_CODE_FOR_APPLICATION_FORM = 'requestForLACodeForApplicationForm';
export const REQUEST_FOR_LA_CODE_FOR_CASE_OR_PROJECT_FORM = 'requestFORLACodeForCaseOrProject';
export const RPA_RUN_BY_REQUEST_FORM = 'rpaRunByRequestForm';
export const STRUCTURED_PRODUCTS_EUROPEAN_OPTION_FORM = 'structuredProductsEuropeanOptionForm';
export const STRUCTURED_PRODUCTS_PEAK_FORM = 'structuredProductsPeakForm';
export const STRUCTURED_PRODUCTS_ASIAN_OPTION_FORM = 'structuredProductsAsianOptionForm';
export const SUPPLIER_QUALIFICATION_REQUEST_FORM = 'supplierQualificationRequestForm';
export const CLOUD_PLATFORM_REQUEST_FORM = 'cloudPlatformRequestForm';
export const CLOUD_PLATFORM_ORDER_FORM = 'cloudPlatformOrderForm';
export const CONTTI_ACCESS_REQUEST_FORM = 'conttiAccessRequestForm';
export const EBOK_HEAT_POLAND_REQUEST_FORM = 'eBokHeatPolandRequestForm';
export const VISUAL_ARCHIVE_FINLAND_ACCESS_REQUEST_FORM = 'visualArchiveFinlandAccessRequestForm';
