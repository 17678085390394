import styled from 'styled-components';
import { IconButton, Box } from 'elemental-ui';

export const PopoverContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const TableBodTd = styled.td`
  color: rgb(65, 65, 74);
  box-sizing: border-box;
  line-height: 1.33;
  height: 3.5rem;
  text-align: right;
  vertical-align: top;
  position: relative;
  padding: 1rem 2rem;
  transition: color 250ms cubic-bezier(0.5, 0, 0, 1) 0s;
  text-align: start;
`;

export const TablaBodyTdPopoverIcon = styled.td`
  color: rgb(65, 65, 74);
  box-sizing: border-box;
  line-height: 1.33;
  height: 3.5rem;
  text-align: right;
  vertical-align: top;
  position: relative;
  padding: 1rem 2rem;
  transition: color 250ms cubic-bezier(0.5, 0, 0, 1) 0s;
  text-align: start;

  &:after {
    content: '\u2807';
    font-size: 25px;
    color:#BEBEC2;
    cursor: pointer
  }
`;

export const EditButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: #3E3E47;
  &:hover {
    background: transparent;
  }
  &:focus {
    background: transparent;
  }
  &::after {
    background: transparent;
  }
`;

export const EditContainer = styled.div`
  border-bottom: 1px solid rgba(232, 232, 235, 0.5);
  display: flex;
  align-items: center;
  padding-right: 105px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  color: #3E3E47;
  justify-content: flex-start;
`;

export const DeleteButton = styled(IconButton)`
width: 40px;
height: 40px;
color: #D25564;
&:hover {
  background: transparent;
}
&:focus {
  background: transparent;
}
&::after {
  background: transparent;
}
`;

export const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 105px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #3E3E47;
  cursor: pointer;
  justify-content: center;
`;
