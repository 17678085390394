import { call, put, takeLatest } from 'redux-saga/effects';

import { sendForm } from '@/api';
import {
  SUBMIT_FORM_REQUEST,
  submitFormResponse,
  submitFormResponseFail,
} from '@/actions';

function* submitForm({ payload, meta }) {
  try {
    const { formData, configuration } = payload;
    const { userId } = meta;
    const response = yield call(
      sendForm,
      formData,
      configuration,
      userId,
    );
    yield response.ok ? put(submitFormResponse(response)) : put(submitFormResponseFail(`Form was not submitted. (Error ${response.status})`));
  } catch (error) {
    console.log('>e', error, error.request);
    yield put(submitFormResponseFail(error.message));
  }
}

export default function* () {
  yield takeLatest(SUBMIT_FORM_REQUEST, submitForm);
}
