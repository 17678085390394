import actionCreator from '@/helpers/redux/actionCreator';

export const CHECK_USER_GROUP_REQUEST = '@/CHECK_USER_GROUP_REQUEST';
export const CHECK_USER_GROUP_RESPONSE = '@/CHECK_USER_GROUP_RESPONSE';
export const CHECK_USER_GROUP_RESPONSE_FAIL = '@/CHECK_USER_GROUP_RESPONSE_FAIL';

export const checkUserGroupsRequest = () => actionCreator(CHECK_USER_GROUP_REQUEST);

export const checkUserGroupsResponse = permissions => actionCreator(CHECK_USER_GROUP_RESPONSE, {
  permissions,
});

export const checkUserGroupsResponseFail = error => actionCreator(CHECK_USER_GROUP_RESPONSE_FAIL, { error });
