import { connect } from 'react-redux';

import Notification from './component';
import { notificationClose } from '@/actions';

const mapStateToProps = null;

const mapDispatchToProps = {
  notificationClose,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
