import { flow3StepsDefaultProps } from '@/helpers/propTypes/flow3steps';

const initialState = { ...flow3StepsDefaultProps };

export default ({
  requestActionType,
  responseActionType,
  failureResponseActionType,
}) => (state = initialState, action = { type: 'undefined', payload: null }) => {
  switch (action.type) {
    case requestActionType: return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: null,
      data: {},
    };

    case responseActionType: return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: null,
      data: action.payload,
    };

    case failureResponseActionType: return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload,
      data: {},
    };

    default: return state;
  }
};
