import { SortableElement } from 'react-sortable-hoc';
import { extractField } from '@/helpers/form/adminPanelFieldExtractor';

const SortableItem = SortableElement((props) => {
  const { field, formikValues } = props;
  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    selectElement,
    closeProperties,
  } = formikValues;

  return extractField({
    field,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    selectElement,
    closeProperties,
  });
});


export default SortableItem;
