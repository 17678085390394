export const mapSelectItems = (items, selected) => {
    let selectedItem;

    const mappedItems = items.map((i) => {
            
            if (i.value === selected) { 
                selectedItem = {label: i.name, value: i.value};
            }
            return {label: i.name, value: i.value};
        })
    
    return {mappedItems, selectedItem};
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});