import React from 'react';
import { connect } from 'react-redux';

import { AUTHENTICATION_PAGE, AZURE_AUTHENTICATION_SCOPES } from '@/constants';
import { userAgentApplication } from '@/helpers/azure/authentication';
import {
  authenticationRequest,
  authenticationResponse,
  authenticationResponseFail,
  authenticationSaveRedirectUrl,
  checkUserGroupsRequest,
  getUserListRequest,
} from '@/actions';

const securedRoute = (Component) => {
  class AuthenticatedComponent extends React.PureComponent {
    async componentDidMount() {
      const isAuthenticated = await this.checkAuthentication();
      const { authenticationSaveRedirectUrl } = this.props;
      if (!isAuthenticated) {
        const { pathname } = this.props.location;
        authenticationSaveRedirectUrl({ pathname });
        this.props.history.push(AUTHENTICATION_PAGE);
      } else {
        getUserListRequest();
      }
    }

    async checkAuthentication() {
      const { authenticationResponse, authenticationResponseFail, checkUserGroupsRequest } = this.props;
      try {
        await userAgentApplication.acquireTokenSilent(
          AZURE_AUTHENTICATION_SCOPES,
        );
        const {
          name,
          displayableId,
          userIdentifier,
        } = userAgentApplication.getUser();
        checkUserGroupsRequest();
        authenticationResponse(name, displayableId, userIdentifier);

        return true;
      } catch (error) {
        authenticationResponseFail();
        return false;
      }
    }

    render() {
      const { isAuthenticated } = this.props;

      return isAuthenticated ? <Component {...this.props} /> : null;
    }
  }

  const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated,
  });

  const mapDispatchToProps = {
    authenticationRequest,
    authenticationResponse,
    authenticationResponseFail,
    authenticationSaveRedirectUrl,
    checkUserGroupsRequest,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthenticatedComponent);
};

export default securedRoute;
