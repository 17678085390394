import React from 'react';

import { Box, Loader } from 'elemental-ui';
import Page from '@/components/blocks/Page';

import PropTypes from 'prop-types';

class AuthenticationPage extends React.PureComponent {
  componentDidMount() {
    const { redirect_url } = this.props;
    const { authenticationRequest } = this.props;
    authenticationRequest({ redirect_url });
  }

  render() {
    return (
      <Page>
        <Box
          marginTop="45vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Loader size={64} alignItems="center" color="#377a6c" />
        </Box>
      </Page>
    );
  }
}

AuthenticationPage.propTypes = {
  authenticationRequest: PropTypes.func.isRequired,
  redirect_url: PropTypes.string.isRequired,
};
AuthenticationPage.defaultProps = {};

export default AuthenticationPage;
