import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CHECK_USER_GROUP_REQUEST,
  checkUserGroupsResponse,
  checkUserGroupsResponseFail,
} from '@/actions';

import { checkUserGroups } from '@/api/checkUserGroups';

function* checkUsersGroups() {
  try {
    const groups = {
      groupIds: [
        process.env.REACT_APP_Co_Azure_ARSForms_Prod_App_Admin,
      ],
    };
    const response = yield call(checkUserGroups, groups);
    if (!!response.value && response.value instanceof Array) {
      const userIsAdmin = response.value.indexOf(process.env.REACT_APP_Co_Azure_ARSForms_Prod_App_Admin) !== -1;
      yield put(checkUserGroupsResponse({ userIsAdmin }));
    } else yield put(checkUserGroupsResponseFail(response));
  } catch (error) {
    yield put(checkUserGroupsResponseFail(error.message));
  }
}

export default function* () {
  yield takeLatest(CHECK_USER_GROUP_REQUEST, checkUsersGroups);
}
