import { compose } from 'redux';
import { connect } from 'react-redux';

import LandingPage from './component';
import { getFormsListRequest, getFormRequest, redirectAction } from '@/actions';

const mapStateToProps = state => ({
  formsList: state.getFormsList.formsList,
  redirected: state.redirect.redirected,
});

const mapDispatchToProps = ({
  getFormsListRequest,
  getFormRequest,
  redirectAction,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LandingPage);
