import React from 'react';
import { AccessDeniedIcon, AccessDeniedContainer, AccessDeniedTitle } from './styles';

const AccessDenied = () => (
  <AccessDeniedContainer>
    <AccessDeniedIcon />
    <AccessDeniedTitle>Access Denied</AccessDeniedTitle>
  </AccessDeniedContainer>
);

export default AccessDenied;
