import actionCreator from '@/helpers/redux/actionCreator';

export const SET_TAG = '@/SET_TAG';

export const SET_TAGS = '@/SET_TAGS';
export const REMOVE_TAG = '@/REMOVE_TAG';

export const setTag = tag => actionCreator(SET_TAG, { tag });
export const setTags = tags => actionCreator(SET_TAGS, { tags });

export const removeTag = id => actionCreator(REMOVE_TAG, { id });
