import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Select,
  Search,
  Loader,
  Table,
  spacing,
  colors,
  Heading,
  Row,
  Col,
} from 'elemental-ui';

import { flow3StepsPropType } from '@/helpers/propTypes/flow3steps';
import {
  SEARCH_FIELD,
  VIEW_FIELD,
  FIELD_BOX_MARGIN_BOTTOM,
  FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM,
} from '@/constants';

import { COLUMNS, VIEWS } from './data';
import {
  CategoriesBlock,
  TableWrapperBlock,
  UnorderedList,
  IconedLink,
  Icon,
  TableResponsive,
  ColTextStart,
} from './styles';

class SelectPeople extends React.PureComponent {
  constructor(props) {
    super(props);

    const { defaultCategory, defaultFilter } = props;

    this.state = {
      activeCategory: defaultCategory || null,
      filter: defaultFilter || ''
    };
  }

  componentDidMount() {
    const { onGetPeopleRequest } = this.props;

    onGetPeopleRequest();
  }

  handleFormSubmit = () => {};

  handleCategoryClick = e => {
    const { activeCategory } = this.state;
    const {
      target: {
        dataset: { id = null }
      }
    } = e;

    this.setActiveCategory(activeCategory !== id ? id : null);
  };

  setActiveCategory(category = null) {
    this.setState({ activeCategory: category });
  }

  setFilter(value = '') {
    this.setState({ filter: value.trim() });
  }

  getTableRows() {
    const { filter, activeCategory } = this.state;
    const {
      selectPeople: {
        data: { users }
      }
    } = this.props;

    if (!users || users.length === 0) {
      return [];
    }

    const targetFieldsConfiguration = this.getActiveCategoryColumns();

    const rows = [];

    users
      .filter(
        user =>
          // Category filter
          user.categories.indexOf(activeCategory) !== -1
      )
      .filter(user => {
        // Form filter
        if (!filter) {
          return user;
        }

        return (
          user.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        );
      })
      .forEach(user => {
        const targetFields = targetFieldsConfiguration.map(
          item => item.dataIndex
        );
        const record = {};

        targetFields.map(fieldName => (record[fieldName] = user[fieldName]));

        rows.push(record);
      });

    return rows;
  }

  getActiveCategoryColumns() {
    const { activeCategory } = this.state;

    if (!activeCategory) {
      return [];
    }

    const targetFieldsConfiguration = COLUMNS.find(
      column => column.id === activeCategory
    );

    if (!targetFieldsConfiguration) {
      return [];
    }

    return targetFieldsConfiguration.data || [];
  }

  handleFilterChange = (handleChange, e) => {
    handleChange(e);

    this.setFilter(e.target.value);
  };

  handleRowClick = displayName => {
    const {
      onSelected,
      selectPeople: {
        data: { users }
      }
    } = this.props;

    if (onSelected) {
      onSelected(users.find(user => user.displayName === displayName));
    }
  };

  renderTable() {
    const { activeCategory } = this.state;

    return (
      <TableWrapperBlock>
        {activeCategory && (
          <TableResponsive>
            <Table
              className="full-width-inner small-table"
              rowKey="displayName"
              theme="zebra"
              columns={this.getActiveCategoryColumns()}
              rows={this.getTableRows()}
              onRowClick={this.handleRowClick}
            />
          </TableResponsive>
        )}
      </TableWrapperBlock>
    );
  }

  renderCategories() {
    const { activeCategory } = this.state;
    const {
      selectPeople: { data }
    } = this.props;

    return (
      <CategoriesBlock>
        <UnorderedList>
          {(data.categories || []).map(category => (
            <li key={category.id}>
              <IconedLink
                noUnderline={activeCategory !== category.id}
                data-id={category.id}
                onClick={this.handleCategoryClick}
              >
                <Icon color={colors.inkGrey} size={20} />
                {category.label}
              </IconedLink>
            </li>
          ))}
        </UnorderedList>
      </CategoriesBlock>
    );
  }

  renderForm = ({ values, handleChange, handleBlur, handleSubmit }) => {
    const {
      selectPeople: { isLoading }
    } = this.props;

    if (isLoading) {
      return (
        <Loader
          size={56}
          color={colors.energeticRedOrange}
          style={{ marginRight: spacing.xxs }}
        />
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Heading marginBottom={spacing.m} level={3}>
              Select People
            </Heading>
          </Col>
        </Row>
        <Row>
          <ColTextStart l={6}>
            <Search
              marginBottom={FIELD_BOX_MARGIN_BOTTOM}
              marginRight={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}
              name={SEARCH_FIELD}
              label="Find"
              borderStyle=""
              value={values[SEARCH_FIELD]}
              onChange={this.handleFilterChange.bind(this, handleChange)}
              onBlur={handleBlur}
            />
          </ColTextStart>
          <ColTextStart l={6}>
            <Select
              marginBottom={FIELD_BOX_MARGIN_BOTTOM}
              label="View"
              className="select__child-label--border-box"
              name={VIEW_FIELD}
              selected={values[VIEW_FIELD]}
              onChange={handleChange}
              onBlur={handleBlur}
              items={VIEWS}
            />
          </ColTextStart>
        </Row>

        <Row>
          <Col l={3}>{this.renderCategories()}</Col>
          <Col l={9}>{this.renderTable()}</Col>
        </Row>
      </form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{
          [SEARCH_FIELD]: '',
          [VIEW_FIELD]: VIEWS[0].value
        }}
        onSubmit={this.handleFormSubmit}
        validate={this.validate}
      >
        {this.renderForm}
      </Formik>
    );
  }
}

SelectPeople.propTypes = {
  selectPeople: PropTypes.shape(flow3StepsPropType),
  onSelected: PropTypes.func,
  defaultCategory: PropTypes.string,
  defaultFilter: PropTypes.string
};

export default SelectPeople;
