import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Pagination } from 'elemental-ui';
import TableRow from '@/components/blocks/TableRow';
import {
  TableResponsive,
  Table,
  TableHeadTh,
  TableBody,
} from './styles';
import arrForPagination from '@/helpers/pagination/arrForPagination';

class TableView extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      activePaginationPage, countPaginationPage,
    } = this.props;
    if (countPaginationPage < prevProps.countPaginationPage
      && prevProps.activePaginationPage === prevProps.countPaginationPage) this.setActivePage(activePaginationPage - 1);
  }

  setActivePage = (activePage) => {
    const { setPaginationPage } = this.props;
    setPaginationPage(activePage);
  }

  render() {
    const { formsList, activePaginationPage, countPaginationPage } = this.props;
    const paginiationArr = arrForPagination(activePaginationPage, formsList, countPaginationPage);
    const rows = paginiationArr.map(rowData => <TableRow key={rowData.document_id} {...rowData} />);
    return (
      <Grid>
        <TableResponsive>
          <Table>
            <thead>
              <tr className="day-table__head">
                <TableHeadTh>Form Name</TableHeadTh>
                <TableHeadTh>Interface ID</TableHeadTh>
                <TableHeadTh>Actions</TableHeadTh>
              </tr>
            </thead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableResponsive>
        {countPaginationPage === 1 ? null : (
          <Pagination
            active={activePaginationPage}
            pages={countPaginationPage}
            color="#377a6c"
            onPageChange={page => this.setActivePage(page)}
          />
        )}
      </Grid>
    );
  }
}

TableView.propTypes = {
  setPaginationPage: PropTypes.func.isRequired,
  activePaginationPage: PropTypes.number.isRequired,
  formsList: PropTypes.array.isRequired,
  countPaginationPage: PropTypes.number.isRequired,
};

TableView.defaultProps = {};

export default TableView;
