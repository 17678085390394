import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_USER_REQUEST,
  getUserResponse,
  getUserResponseFail,
  setTag,
} from '@/actions';

import { getUser } from '@/api/getUser';

function* getUserRequest({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(getUser, id);
    if (Object.prototype.hasOwnProperty.call(response, 'error')) {
      yield put(getUserResponseFail('User not found'));
      return false;
    }
    const tag = Object.assign({}, {
      name: `${response.surname} ${response.givenName}`,
      email: response.userPrincipalName,
    });
    yield put(getUserResponse(tag));
    yield put(setTag(tag));
    return true;
  } catch (error) {
    yield put(getUserResponseFail(error.message));
    return false;
  }
}

export default function* () {
  yield takeLatest(GET_USER_REQUEST, getUserRequest);
}
