import { FORTUM_FORMS_BACKEND_ENDPOINT } from '@/constants';

export const getFormsList = () => fetch(`${FORTUM_FORMS_BACKEND_ENDPOINT}/api/forms-list`, {
  method: 'GET',
  headers: {
    'Access-Control-Allow-Origin': FORTUM_FORMS_BACKEND_ENDPOINT,
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Content-Type': 'application/json',
  },
}).then(res => res.json());
