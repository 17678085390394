import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentText,
  Box,
  Grid,
  InputField,
  IconDelegate,
} from 'elemental-ui';
import {
  FIELD_BOX_MARGIN_BOTTOM,
  FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM,
} from '@/constants';
import {
  ModalStyled,
  InputTagTags,
  InputTagTagsLi,
  InputTagTagsLiButton,
} from './styles';
import SelectPeople from '@/components/forms/SelectPeople';

class InputContactPerson extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      validationError: '',
      tags: [],
    };
    this.tagInput = React.createRef();
  }

  inputKeyDown = (e, setFieldValue, field) => {
    const { getUserRequest, tags } = this.props;
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      if (
        this.state.tags.find(tag => { return tag.email.toLowerCase() === val.toLowerCase() || tag.name.toLowerCase() === val.toLowerCase()})
      ) {
        this.setState({
          validationError: 'User already added',
        });
        return;
      }
      if ( !tags.find(tag => {return tag.email.toLowerCase() === val.toLowerCase() || tag.name.toLowerCase() === val.toLowerCase()} ) ) {
        this.setState({
          validationError: 'No such user',
        });
        return;
      }
      var ntag = tags.find(tag => { if (tag.email.toLowerCase() === val.toLowerCase() || tag.name.toLowerCase() === val.toLowerCase() ) { return tag }});
      if (ntag) {
        const nTags = [].concat(ntag, this.state.tags);
        this.setState({
          tags: nTags,
          validationError: '',
          opened: false,
        });
      } else {
        this.setState({
          validationError: 'Unknown error',
        });
        return;
      }
      const emails = this.state.tags.map(tag => tag.email);
      setFieldValue(field.name, [].concat(emails, ntag.email));
      e.target.value = '';
    }
  };

  handleOpen = () => this.setState({ opened: true });

  handleClose = () => this.setState({ opened: false });

  handleRemoveTag = (i) => {
    const {
      removeTag, setFieldValue, field, tags,
    } = this.props;
    const newTags = [].concat(this.state.tags);
    newTags.splice(i, 1);
    //removeTag(i);
    this.setState({ tags: newTags });
    setFieldValue(field.name, newTags.map(tag => tag.email));
  };

  render() {
    const {
      field,
      setFieldValue,
      touched,
      errors,
      handleBlur,
    } = this.props;
    const { opened, validationError } = this.state;
    const { tags, requestError } = this.props;
    return (
      <Box marginBottom={FIELD_BOX_MARGIN_BOTTOM} key={field.name}>
        <ContentText marginBottom={FIELD_BOX_CONTENT_TEXT_MARGIN_BOTTOM}>
          {field.context ? field.context : ''}
        </ContentText>
        <InputTagTags>
          { this.state.tags.map((tag, i) => (
            <InputTagTagsLi key={tag.name}>
              {tag.name}
              <InputTagTagsLiButton
                type="button"
                onClick={() => { this.handleRemoveTag(i); }}
              >
                  +
              </InputTagTagsLiButton>
            </InputTagTagsLi>
          ))}
          <InputField
            type="text"
            defaultValue="123"
            autoComplete="off"
            label={field.label}
            onKeyDown={event => this.inputKeyDown(event, setFieldValue, field)}
            inputRef={(c) => { this.tagInput = c; }}
            onBlur={handleBlur}
            error={!!(touched[field.name] && errors[field.name])}
            errorMessage={errors[field.name] ? errors[field.name] : touched[field.name]}
            id={field.name}
            name={field.name}
            required={field.required}
            rightIcon={<IconDelegate />}
          />
        </InputTagTags>
        <span style={{ color: '#eb6437', marginTop: '15px' }}>{validationError || requestError}</span>

        <ModalStyled opened={opened} onClose={this.handleClose}>
          <Grid>
            <SelectPeople />
          </Grid>
        </ModalStyled>
      </Box>
    );
  }
}

InputContactPerson.propTypes = {
  field: PropTypes.object.isRequired,
  getUserRequest: PropTypes.func.isRequired,
  requestError: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  removeTag: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
};
InputContactPerson.defaultProps = {};

export default InputContactPerson;
