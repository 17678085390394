import { compose } from 'redux';
import { connect } from 'react-redux';

import { submitFormRequest, cancelSubmit, getFormRequest } from '@/actions';

import DynamicForm from './component';

const mapStateToProps = state => ({
  isSubmitting: state.form.isSubmitting,
  userId: state.user.displayableId,
  form: state.getForm.form,
  formIsRequested: state.getForm.isRequested,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  submitFormRequest,
  cancelSubmit,
  getFormRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DynamicForm);
