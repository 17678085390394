import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Header, spacing, Link, Popover,
} from 'elemental-ui';
import {
  HeaderLogo, HeaderUserInfo,
  HeaderUserInfoAvatar,
  HeaderUserNoAvatar,
  HeaderUserInfoName,
  Arrow,
  PopoverContainer,
  LinkButton,
  HeaderContainer,
  HeaderUserInfoContainer,
} from './styles';
import { userAgentApplication } from '@/helpers/azure/authentication';
import Logo from '../Logo';


class Head extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { opened: false };
  }

  logoutHandle = () => {
    userAgentApplication.logout();
  }

  handleOpen = e => this.setState({ opened: true, anchor: e.currentTarget });

  handleClose = () => this.setState({ opened: false });

  render() {
    const { opened, anchor } = this.state;
    const { name, userIsAdmin } = this.props;
    const photoReceived = false;
    const nameSplit = name?.split(' ') || ["Example", "Doe"];
    //const initials = name.split(' ').map(item => item.charAt(0));
    return (
      <HeaderContainer>
          <Logo onClick={(e) => { if (window.history.length > 1) { e.preventDefault(); window.history.back() } }} href="/" />
          <HeaderUserInfoContainer>
              <HeaderUserInfo type="button" onClick={this.handleOpen}>
                
                <HeaderUserInfoName>
                  {nameSplit[0]}
                  {' '}
                  {nameSplit[1]}
                </HeaderUserInfoName>
                <Arrow />
              </HeaderUserInfo>
              <Popover
                onClose={this.handleClose}
                opened={opened}
                anchor={anchor}
                anchorPos="topRight"
                backgroundColor="white"
                textColor="black"
              >
                <PopoverContainer m={spacing.s}>
                  {userIsAdmin ? <Link href="/admin-panel/forms-list" noUnderline>Admin Panel</Link> : null}
                  <LinkButton onClick={this.logoutHandle}>Log out</LinkButton>
                </PopoverContainer>
              </Popover>
          </HeaderUserInfoContainer>
      </HeaderContainer>
    );
  }
}


/**
 * 
 *     <Header
        backgroundColor="white"
        foregroundColor="#BEBEC2"
        leftComponent={<HeaderLogo onClick={(e) => { if (window.history.length > 1) { e.preventDefault(); window.history.back() } }} href="/" />}
        rightComponent={(
          <Header.WidgetWrapper>
            <Box>
              <HeaderUserInfo type="button" onClick={this.handleOpen}>
                {photoReceived ? <HeaderUserInfoAvatar /> : <HeaderUserNoAvatar>{`${initials[0]}${initials[1]}`}</HeaderUserNoAvatar> }
                <HeaderUserInfoName>
                  {nameSplit[0]}
                  {' '}
                  {nameSplit[1]}
                </HeaderUserInfoName>
                <Arrow />
              </HeaderUserInfo>
              <Popover
                onClose={this.handleClose}
                opened={opened}
                anchor={anchor}
                anchorPos="topRight"
                backgroundColor="white"
                textColor="black"
              >
                <PopoverContainer m={spacing.s}>
                  {userIsAdmin ? <Link href="/admin-panel/forms-list" noUnderline>Admin Panel</Link> : null}
                  <LinkButton onClick={this.logoutHandle}>Log out</LinkButton>
                </PopoverContainer>
              </Popover>
            </Box>
          </Header.WidgetWrapper>
 * 
 * 
 */

Head.propTypes = {
  name: PropTypes.string.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

Head.defaultProps = {
};

export default Head;
