import React from 'react'
import PropTypes from 'prop-types'
import { RadioButtonInput, RadioFormControl } from './styles'
import { Text } from "@/components/blocks/Text"
import { Row } from '../Grid'

export function RadioButtonItems(props) {
    const { label, required, error, errorMessage, name, id, onChange, items } = props;

    return (
        <div>
            {items.map((b, i) => (
                <RadioFormControl key={i}>
                    <RadioButtonInput {...props} id={id} name={name} type="radio" value={b.value} onChange={onChange} />
                    {b.label}
                </RadioFormControl>
            ))}
            <Row>
                {error && <Text variant="warning">{errorMessage}</Text>}
            </Row>
        </div>
    )
}
RadioButtonItems.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
}

RadioButtonItems.defaults = {
    label: "Placeholder",
    required: false
}