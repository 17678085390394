import styled from 'styled-components';

export const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.33;
  font-weight: 400;
  font-style: normal;
  color: rgb(67, 115, 181);

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;
