import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Row } from 'elemental-ui';

import SortableItem from '@/components/blocks/SortableItem';

const SortableList = SortableContainer(({ items }) => (
  <Row>
    {items.map((item, index) => (
      <SortableItem key={`item-${item.id}`} index={index} field={item} />
    ))}
  </Row>
));


export default SortableList;
