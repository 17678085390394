import { spacing } from 'elemental-ui';

export const checkboxStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing.xxs,
};

export const radioButtonStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing.xxs,
};
